import { SemiBold14Text, SemiBold18Text, SnapPrimaryButton } from "@snapmint/ui-components"
import { useApp } from "../../app.hook"
import { BASE_PATH } from "../../enum/base.enum"

export const PopUpBlockedSmallComponent = () => {

    const { retryPopUpBlocker, blockCount, smallPopUpCopy } = useApp()

    return (
        <div className="bg-[#00000060] h-[100%] w-[100%] fixed top-0 z-[9999999]">
            <div className="max-w-[374px] h-[232px] bg-[#FFFFFF] fixed w-[100%] bottom-0 flex flex-col items-center rounded-t mx-auto left-0 right-0 px-[32px]">
                {blockCount > 1 ? <>
                    <img
                        src={`${BASE_PATH}/assets/images/popup-error.svg`}
                        className="h-[42px] w-[42px] mx-auto my-[14px] cursor-pointer"
                        alt=""
                    />
                    <SemiBold18Text className="text-[#000000]" text={smallPopUpCopy.retryTitle} />
                    <SemiBold14Text className="mt-[14px] text-justify text-[#000000]" text={smallPopUpCopy.retryDescription} />
                </> : <>
                    <img
                        src={`${BASE_PATH}/assets/images/popup-success.svg`}
                        className="h-[42px] w-[42px] mx-auto my-[32px] cursor-pointer"
                        alt=""
                    />
                    <SemiBold14Text className="text-justify text-[#000000]" text={smallPopUpCopy.title} />
                </>
                }

                <SnapPrimaryButton
                    text={blockCount > 1 ? smallPopUpCopy.retryButton : smallPopUpCopy.button}
                    active={true}
                    onClick={() => retryPopUpBlocker()}
                    className="w-[100%] mt-[16px] mb-[20px]"
                />
            </div>
        </div>
    )
}

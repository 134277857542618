type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

function captureGtmEvent(eventName: string, props?: Record<string, any>) {
    try {
        window.dataLayer?.push({
            event: eventName,
            values: props || {}
        });
    } catch (err) {
        console.log(err);
    }
}

export const gtm = {
    captureGtmEvents
};

function captureGtmEvents(eventName: string, properties?: Record<string, string>) {
    captureGtmEvent(eventName, properties);
}

import { useState } from 'react';
import { useOtp } from '../../page/otp/otp.hook';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';

const OTPTermCondition: React.FC<{ tncExperiment: boolean }> = ({ tncExperiment }) => {
    const [readMore, setReadMore] = useState(false);
    const { termsAndConditionUrl, privacyPolicyUrl } = useOtp();

    const handleTncClick = () => {
        captureEvent(EventName.OTP_TNC_CLICK , {}, false, true)
    }

    return (
        <div className="flex">
            <img src="/assets/images/gray-checkbox.svg" alt="checkbox" className="w-[6px] h-[6px] mt-[3px] mr-[2px]" />
            {tncExperiment || readMore ? (
                <span className="text-[7px] text-justify mx-[0px] text-[#90A3A7] font-interRegular">
                    Snapmint, may use my credit history & ckyc data to confirm my eligibility. By continuing I am
                    consenting to Snapmint’s{' '}
                    <a className="cursor-pointer" href={termsAndConditionUrl} target="_blank" onClick={handleTncClick}>
                        Terms & Conditions&nbsp;
                    </a>
                    &&nbsp;
                    <a className="cursor-pointer" href={privacyPolicyUrl} target="_blank" onClick={handleTncClick}>
                        Privacy Policy&nbsp;
                    </a>
                    and agree to be contacted via Call & SMS
                </span>
            ) : (
                <span className="text-[7px] font-interRegular text-[#90A3A7]">
                    Snapmint, may use my credit history & ckyc data to confirm my eligibility. By co..
                    <span className="cursor-pointer" onClick={() => {setReadMore(true); handleTncClick()}}>
                        read more
                    </span>
                </span>
            )}
        </div>
    );
};

export default OTPTermCondition;

interface SnapShimmerProps {
    className?: string;
    componentName?: string;
}

export const SnapShimmer = ({ className }: SnapShimmerProps) => {
    return (
        <div className={`shimmer-container ${className}`}>
            <div className="shimmer animate flex items-end justify-end" />
        </div>
    );
};

import { Medium18Text, Regular10Text, SnapBody1, SnapHeading4, SnapLoader } from "@snapmint/ui-components";
import { BASE_PATH } from "../../enum/base.enum";

export const PreEmiLoaderComponent = () => {
    return (
        <div className="px-[16px]">
            <SnapLoader
                loop={true}
                autoplay={true}
                width={100}
                height={100}
                className="mt-[8px]"
            />
            <Medium18Text text="Verifying Credit Eligibility" className="text-[#ACACAC] mt-[32px] text-center" />
            <div className="flex flex-col justify-start gap-[10px] my-[24px]">
                <div className="flex justify-start gap-[6px] items-center">
                    <img
                        src={`${BASE_PATH}/assets/images/emi-approved-img.svg`}
                        alt="emi-approved-image"
                        className="w-[12px] h-[12px]"
                    />
                    <Regular10Text
                        className="text-[#000000]"
                        text="Please pay your EMIs on time to maintain a good credit score"
                    />
                </div>
                <div className="flex justify-start gap-[6px] items-center">
                    <img
                        src={`${BASE_PATH}/assets/images/emi-approved-img.svg`}
                        alt="emi-approved-image"
                        className="w-[12px] h-[12px]"
                    />
                    <Regular10Text
                        className="text-[#000000]"
                        text="Pay EMIs online via UPI, Debit card or Net banking"
                    />
                </div>
                <div className="flex justify-start gap-[6px] items-center">
                    <img
                        src={`${BASE_PATH}/assets/images/emi-approved-img.svg`}
                        alt="emi-approved-image"
                        className="w-[12px] h-[12px]"
                    />
                    <Regular10Text
                        className="text-[#000000]"
                        text="You will receive monthly reminders via SMS and Email"
                    />
                </div>
            </div>
        </div>
    );
};

import { lazy } from 'react';
import { getLocalStorageKey, setLocalStorageKey } from './local-stoarge.helper';

const COMPONENT_REFRESH_KEY = 'snap-ck-page-has-been-force-refreshed';

export const lazyWithRetry = <T extends () => Promise<any>>(componentImport: T) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(getLocalStorageKey(COMPONENT_REFRESH_KEY) || 'false');

        try {
            const component = await componentImport();

            try {
                setLocalStorageKey(COMPONENT_REFRESH_KEY, 'false');
            } catch (error) {
                console.log(error);
            }

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                setLocalStorageKey(COMPONENT_REFRESH_KEY, 'true');
                return window.location.reload();
            }
            throw error;
        }
    });

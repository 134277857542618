import Axios, { AxiosResponse } from "axios";
import { BASE_API_PATH } from "../enum/base.enum";
import { SendOTPReq, ValidateOTPReq } from "../request/login.req";
import { getLocalStorageKey } from "../utils/local-stoarge.helper"

export const UserService = {
    moveNext: (checkoutId: string, data: any): Promise<AxiosResponse<any>> => {
        const cashbackAmount = getLocalStorageKey(`${checkoutId}_cashback_applied`);
        return Axios.post(`${BASE_API_PATH}/checkout?checkoutId=${checkoutId}`, {
            ...(cashbackAmount && { cashback: parseInt(cashbackAmount) }),
            ...(data && data)
        });
    },
    sendOTP(checkoutId: string, sendOTPReq: SendOTPReq) {
        return Axios.post(`${BASE_API_PATH}/login/send-otp?checkoutId=${checkoutId}`, sendOTPReq)
    },
    validateOTP(checkoutId: string, validateOTPReq: ValidateOTPReq) {
        return Axios.post(`${BASE_API_PATH}/login/validate-otp?checkoutId=${checkoutId}`, validateOTPReq)
    },
    logout() {
        return Axios.post(`${BASE_API_PATH}/logout`)
    },
    isTncAgreed(checkoutId: string): Promise<AxiosResponse<{ tncAgreed: boolean }>> {
        return Axios.get(`${BASE_API_PATH}/users/tnc?checkoutId=${checkoutId}`);
    },
    acceptTnc(checkoutId: string) {
        return Axios.post(`${BASE_API_PATH}/users/tnc?checkoutId=${checkoutId}`);
    }
}

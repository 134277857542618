import { Action } from './model/reducer.model';
import { isWebView } from './utils/web-view.helper';

export interface SmallPopUpCopy {
    title: string;
    button: string;
    retryTitle: string;
    retryDescription: string;
    retryButton: string;
}

export interface MerchantHeaderDetails {
    showMerchantHeader: boolean;
    merchantLogo: string;
    merchantHeaderTitle: string;
    isLoaded: boolean;
}

export interface CashbackConfig {
    availableCashBack: number;
    applicableCashBack: number;
    cashbackAmtApplied: number;
    cashbackPercent: number;
    cashBackDetailsLoaded: boolean;
}

export interface AppState {
    popUpBlocked: boolean;
    blockCount: number;
    smallView: boolean;
    retry: () => void;
    smallPopUpCopy: SmallPopUpCopy;
    webView: boolean;
    isIframe: boolean;
    viewType: string;
    merchantHeaderDetails: MerchantHeaderDetails;
    cashbackConfig: CashbackConfig
}

export const getInitialState = (merchantHeaderDetails: Partial<MerchantHeaderDetails>): AppState => {
    return {
        popUpBlocked: false,
        blockCount: 0,
        smallView: true,
        retry: () => {},
        webView: false,
        isIframe: false,
        viewType: '',
        merchantHeaderDetails,
        cashbackConfig: {
            availableCashBack: 0,
            applicableCashBack: 0,
            cashbackAmtApplied: 0,
            cashbackPercent: 0,
            cashBackDetailsLoaded: false
        }
    } as AppState;
};

export enum AppAction {
    UPDATE_POP_UP_BLOCKED = 'UPDATE_POP_UP_BLOCKED',
    UPDATE_RE_TRY_FUNCTION = 'UPDATE_RE_TRY_FUNCTION',
    UPDATE_BLOCK_COUNT = 'UPDATE_BLOCK_COUNT',
    UPDATE_WEB_VIEW = 'UPDATE_WEB_VIEW',
    UPDATE_IS_IFRAME = 'UPDATE_IS_IFRAME',
    UPDATE_VIEW_TYPE = 'UPDATE_VIEW_TYPE',
    UPDATE_MERCHANT_HEADER_DETAILS = 'UPDATE_MERCHANT_HEADER_DETAILS',
    UPDATE_CASHBACK_CONFIG = 'UPDATE_CASHBACK_CONFIG',
}

export const AppReducer = (state: AppState, action: Action): AppState => {
    const { type, payload } = action;

    switch (type) {
        case AppAction.UPDATE_POP_UP_BLOCKED:
            return {
                ...state,
                popUpBlocked: payload.popUpBlocked,
                blockCount: 1,
                smallView: payload.smallView,
                smallPopUpCopy: { ...payload.smallPopUpCopy }
            };
        case AppAction.UPDATE_BLOCK_COUNT:
            return {
                ...state,
                blockCount: state.blockCount + 1
            };
        case AppAction.UPDATE_RE_TRY_FUNCTION:
            return {
                ...state,
                retry: payload.retry
            };
        case AppAction.UPDATE_IS_IFRAME:
            return {
                ...state,
                isIframe: payload.isIframe
            };
        case AppAction.UPDATE_VIEW_TYPE:
            return {
                ...state,
                viewType: payload.viewType,
                webView: isWebView(payload.viewType)
            };
        case AppAction.UPDATE_MERCHANT_HEADER_DETAILS:
            return {
                ...state,
                merchantHeaderDetails: payload.merchantHeaderDetails
            }
        case AppAction.UPDATE_CASHBACK_CONFIG:
            return {
                ...state,
                cashbackConfig: {
                    ...state.cashbackConfig,
                    ...payload.cashbackConfig
                }
            }
            
        default:
            throw new Error(`No case for type ${type} found in AppReducer.`);
    }
};

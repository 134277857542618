import { MerchantHeaderDetails } from '../../app.reducer';
import { getLocalStorageKey } from '../../utils/local-stoarge.helper';
import { useUrlParamParser } from './url-param-parse.hook';

const useMerchantHeaderDetails = (): MerchantHeaderDetails => {
    const transactionParamModel = useUrlParamParser();

    const getMerchantHeaderDetails = () => {
        const { showMerchantHeader = false, merchantLogo = '', merchantHeaderTitle = '', checkoutId } = transactionParamModel;
        const isMerchantParamsExist = showMerchantHeader && !!merchantLogo && !!merchantHeaderTitle;
        if (isMerchantParamsExist) {
            return {
                showMerchantHeader,
                merchantHeaderTitle,
                merchantLogo,
                isLoaded: true
            };
        } else {
            const merchantHeaderDetailsLocal = getLocalStorageKey(`${checkoutId}_merchant_header_details` as string);
            if (!merchantHeaderDetailsLocal) {
                return {
                    isLoaded: false,
                    merchantLogo: '',
                    merchantHeaderTitle: '',
                    showMerchantHeader: false
                };
            }
            const {
                showMerchantHeader = false,
                merchantLogo = '',
                merchantHeaderTitle = ''
            } = JSON.parse(merchantHeaderDetailsLocal);
            return {
                showMerchantHeader,
                merchantLogo,
                merchantHeaderTitle,
                isLoaded: true
            };
        }
    };
    const { isLoaded, merchantHeaderTitle, merchantLogo, showMerchantHeader } = getMerchantHeaderDetails();
    return {
        isLoaded,
        merchantHeaderTitle,
        merchantLogo,
        showMerchantHeader
    };
};

export default useMerchantHeaderDetails;

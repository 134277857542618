import { useEffect, useState } from 'react';
import { UserService } from '../../service/user.service';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { registerUser, authenticateUser, processResp } from '../../modules/ashield';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { CommonLoaderComponent } from '../../component/loader/common-loader.component'
import { LogsService } from '../../service/logs.service'
import { LogLevel } from '../../enum/enum'
import { AshieldService } from '../../service/ashield.service'
import OtpBypassConfirm from './otp-bypass-confirm'
import { captureEvent } from '../../events/event.helper'
import { useApp } from '../../app.hook'
import { EventName } from '../../events/event-name.enum'

const OtpBypass = () => {
    const transactionParamModel = useUrlParamParser();
    const { checkoutId, mobile, resp, merchantId, merchantName, orderValue } = transactionParamModel;
    const { checkRedirection } = useRedirectionHelper();
    const { getViewtype } = useApp();
    
    const captureOtpBypassEvents = async (eventName: string, properties = {}) => {
        const viewType = await getViewtype();
        captureEvent(eventName, {
            cart_id: checkoutId,
            merchant_id: merchantId,
            merchant_name: merchantName,
            mobile,
            order_value: orderValue,
            view_type: viewType,
            ...properties
        });
    };
    
    const goNext = async (data?: Record<string, boolean | string>) => {
        let id = transactionParamModel?.checkoutId ? transactionParamModel?.checkoutId : '';
        const res = await UserService.moveNext(id, data);
        checkRedirection(res.data);
    };

    const onOtpBypassFail = async (errorCode: string, errorMsg: string, step?: string) => {
        localStorage.removeItem(`ashield_registered_${mobile}`);
        LogsService.log({
            level: LogLevel.ERROR,
            message: `Ashield verification failed for mobile ${mobile} cart ${checkoutId} - ${errorCode} ${errorMsg}`
        });
        captureOtpBypassEvents(EventName.ASHIELD_API, {
            step,
            status_code: errorCode
        });
        goNext({ token: transactionParamModel.token as string, otpBypassFailed: true });
    };

    const onOtpBypassSuccess = async (step: string) => {
        try {
            await AshieldService.authorize(checkoutId as string);
            localStorage.setItem(`ashield_registered_${mobile}`, 'true');
            LogsService.log({
                level: LogLevel.INFO,
                message: `Ashield verification successful for cart - ${checkoutId}`
            });
            captureOtpBypassEvents(EventName.ASHIELD_API, {
                step,
                status_code: 200,
            });
            goNext();
        } catch (error) {
            onOtpBypassFail('', 'ASHIELD_VERIFICATION_UNSUCCESSFUL');
        }
    };


    const handleOtpBypass = async () => {
        const {
            data: { signature }
        } = await AshieldService.getSignature(checkoutId as string);
        const isAshieldRegistered = localStorage.getItem(`ashield_registered_${mobile}`);
        const ashieldMid = process.env.REACT_APP_ASHIELD_MID;

        if (isAshieldRegistered) {
            captureOtpBypassEvents(EventName.ASHIELD_INIT);
            authenticateUser(
                mobile,
                ashieldMid,
                checkoutId,
                signature,
                {},
                (errorCode: string, errorMsg?: string) =>
                    onOtpBypassFail(errorCode, errorMsg as string, 'authenticate'),
                () => onOtpBypassSuccess('authenticate')
            );
        } else {
            if (resp) {
                processResp(
                    resp,
                    (errorCode: string, errorMsg?: string) =>
                        onOtpBypassFail(errorCode, errorMsg as string, 'register_redirect'),
                    () => onOtpBypassSuccess('register_redirect')
                );
            } else {
                captureOtpBypassEvents(EventName.ASHIELD_INIT);
                registerUser(
                    mobile,
                    ashieldMid,
                    checkoutId,
                    window.location.href,
                    signature,
                    {},
                    (errorCode: string, errorMsg?: string) =>
                        onOtpBypassFail(errorCode, errorMsg as string, 'register')
                );
            }
        }
    };

    useEffect(() => {
        handleOtpBypass();
    }, []);

    return (
        <div className="max-w-[374px] mx-auto relative flex">
            <CommonLoaderComponent />
        </div>
    );
};

export default OtpBypass;

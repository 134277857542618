import { Medium18Text, Regular10Text, SnapBody1, SnapHeading4, SnapLoader } from '@snapmint/ui-components';

export const CommonLoaderComponent = () => {
    return (
        <div className="max-w-[374px] mx-auto px-[16px] mt-[184px]">
            <img
                src="https://assets.snapmint.com/assets/merchant/emitxt/snaplogogreen.svg"
                className="rotate"
                style={{
                    height: 100,
                    marginTop: 8,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex'
                }}
            />
            <p className="snap-loader-text mt-6">Processing Your EMI Purchase</p>
        </div>
    );
};

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCookieHelper = () => {
    const iframeUrl = useRef('');
    let retryCount = useRef(0);
    const [isCookieEnabled, setIsCookieEnabled] = useState(false);

    const navigate = useNavigate();

    const checkCookiesEnable = () => {
        let isCookieEnabled = false;
        try {
            isCookieEnabled = window.navigator.cookieEnabled ? true : false;
            if (typeof window.navigator.cookieEnabled == 'undefined' && !isCookieEnabled) {
                document.cookie = 'testcookie';
                isCookieEnabled = document.cookie.indexOf('testcookie') != -1;
            }
        } catch {
            isCookieEnabled = false;
        }

        if (!isCookieEnabled && retryCount.current <= 2) {
            retryCount.current = retryCount.current + 1;
            setTimeout(() => {
                checkCookiesEnable();
            }, 1000);
        }
        setIsCookieEnabled(isCookieEnabled);
        return isCookieEnabled || showCookieFail();
    };

    const showCookieFail = () => {
        navigate('/cookie-blocked', { state: { iframeUrl: iframeUrl.current }, replace: true });
    };

    useEffect(() => {
        iframeUrl.current = document.location.href;
        checkCookiesEnable();
    }, []);

    return {
        isCookieEnabled
    }
};

import { Medium12Text, Regular10Text, SemiBold10Text, SnapPrimaryButton } from "@snapmint/ui-components"
import { FooterComponent } from "../footer/footer.component"
import { BASE_PATH } from "../../enum/base.enum"
import { useApp } from "../../app.hook"

export const PopUpBlockedComponent = () => {

    const { retryPopUpBlocker } = useApp()

    return (
        <div className="bg-white h-[100vh] w-[100%] fixed top-0 z-[9999999]">
              <div className="max-w-[374px] bg-[#FFFFFF] fixed w-[100%] h-[100vh] top-0 bottom-0 flex flex-col items-center rounded-t-md mx-auto left-0 right-0">
                
        <div className="text-center">
            <div className="bg-[#0347F7] max-w-[374px] h-[40px] fixed w-[100%] mx-auto top-0 flex items-center z-[999999]">
                <img
                    src={`${BASE_PATH}/assets/images/snap_logo.svg`}
                    className="h-[24px] ml-4"
                    alt=""
                />
            </div>
            <img src={`${BASE_PATH}/assets/images/cookie-blocked.svg`} className="w-[74px] mx-auto pt-[74px]" alt="" />
            <Medium12Text text="UH oh !" className="text-[#000000] leading-[15.98px] mt-[28px]" />
            <SemiBold10Text className="text-[#000000] mx-[16px] mt-[8px] leading-[13.6px]" text={"Looks like you are using a pop-up blocker"} />
            <Regular10Text className="text-[#000000] mx-[16px] mt-[10px] leading-[13.6px]" text="To complete EMI purchase please disable pop up blocker and click the retry button" />
            <SnapPrimaryButton onClick={() => { retryPopUpBlocker() }} className={"mx-auto mt-[16px] min-w-[74%]"} active={true} text={"Retry EMI Purchase"} />

            <FooterComponent />
        </div>
        </div>
        </div>
    )
}

import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { OrderDetailAfterPlanComponent } from './order-detail-after-plan.component';
import { OrderDetailComponent } from './order-detail.component';

interface IshowCashBack {
    showCashBack?: boolean;
}
export const OrderDetailSuperComponent = ({ showCashBack = false }: IshowCashBack) => {
    const _transactionParamModel = useUrlParamParser();
    const { emiPlanId } = _transactionParamModel;

    if (emiPlanId !== null) {
        return <OrderDetailAfterPlanComponent />;
    }

    return (
        <>
            <OrderDetailComponent showCashBack={showCashBack} />
        </>
    );
};

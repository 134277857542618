import { useContext, useEffect, useState } from 'react';
import {
    Bold10Text,
    Bold12Text,
    Bold13Text,
    Bold7Text,
    Medium10Text,
    Medium12Text,
    Medium8Text,
    Regular10Text,
    SemiBold12Text,
    SemiBold13Text,
    SemiBold8Text,
    SnapBadge,
    SnapCard
} from '@snapmint/ui-components';
import { Plan } from '../../response/plan.res';
import { PopUpModal } from '../pop-up-model/pop-up-model';
import { PublicPlanSelectionComponent } from '../public-plan-selection/public-plan-selection-compont';
import { usePublicPlan } from '../../page/common/plan.hook';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { SnapShimmer } from '../shimmer/shimmer.component';
import { BASE_PATH } from '../../enum/base.enum';
import { CashbackComponent } from '../cashback/cashback.component';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashEnum } from '../../enum/unleash-enum';
import { PublicPlanSelectionComponent2 } from '../public-plan-selection/public-plan-selection-component2';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';
import { isPayLater, setPayLater } from '../../utils/pay-later.helper';
import { getLocalStorageKey, setLocalStorageKey } from '../../utils/local-stoarge.helper';
import { useOtp } from '../../page/otp/otp.hook';
import { useApp } from '../../app.hook'
import { CashbackRedeem } from './cashback-detail.component'

interface IshowCashBack {
    showCashBack?: boolean;
}
export const OrderDetailComponent = ({ showCashBack = false }: IshowCashBack) => {
    const isCashBackEnabled = useFlag(UnleashEnum.NEW_CHECKOUT_CASHBACK);
    const transactionParamModel = useUrlParamParser();
    const { planList, fetchUserPlan } = usePublicPlan();
    const { checkoutId, merchantName, orderValue } = transactionParamModel;
    const {updateCheckPayLater} = useOtp();
    const [plan, setPlan] = useState<Plan>();
    const [orderVal, setOrderVal] = useState<Number>(0);
    const planOptionShow = useFlag(UnleashEnum.PLAN_CHANGE_COMPONENT);
    const checkIsPayLater = isPayLater();
    const emiOrPayLaterText = checkIsPayLater ? 'Pay Later' : 'EMI';
    const snapBadgeText = checkIsPayLater ? '0 Extra Cost' : '0% EMI';
    const snapAppText = checkIsPayLater ? 'Pay Later on Snapmint App' : 'Pay EMIs on Snapmint App';
    const {
        cashbackConfig: { cashBackDetailsLoaded },
        divideCashBack,
        isCashBackUsed
    } = useApp();

    useEffect(() => {
        if (planList && planList.length > 0) {
            let _plan: any = planList.filter((plan: Plan) => {
                return plan.displayPlan;
            })[0];
            const planTenure = JSON.parse(getLocalStorageKey(`default_plan_tenure_${checkoutId}`) || '');
            if (planTenure) {
                _plan = planList.filter((plan: Plan) => {
                    return plan?.tenure?.toString() == planTenure;
                })[0];
            }
            if (_plan && !_plan.tdp && _plan?.tdp !== 0) {
                if (!_plan.tdp) {
                    _plan.tdp = _plan.dp;
                }

                if (!_plan.tdp && _plan.dp) {
                    _plan.tdp = _plan.dp;
                }
                if (!_plan.emiAmount) {
                    _plan.emiAmount = _plan.emi;
                }
                if (!_plan.emiStartDate && _plan.startAt) {
                    _plan.emiStartDate = _plan.startAt;
                    const endDate = new Date(_plan.startAt);
                    endDate.setMonth(endDate.getMonth() + (_plan.tenure - 1));
                    _plan.emiEndDate = endDate;
                }
            }
            if (_plan && _plan.tenure === 1) {
                setPayLater(true);
            } else {
                setPayLater(false);
            }
            if (updateCheckPayLater) {
                updateCheckPayLater(_plan.tenure === 1);
            }
            setPlan(_plan);
        }
    }, [planList]);

    useEffect(() => {
        if (checkoutId) {
            fetchUserPlan(checkoutId);
        }
    }, [checkoutId]);

    const [openPlanSelector, setOpenPlanSelector] = useState(false);

    const togglePlanSelector = () => {
        setOpenPlanSelector((preValue) => {
            return !preValue;
        });
        if (openPlanSelector) {
            let eventData: any = {};
            if (transactionParamModel) {
                let currentPage = window.location.pathname;
                const { merchantName, merchantId, checkoutId, userId, mobile } = transactionParamModel;
                eventData.cart_id = checkoutId;
                eventData.merchant_id = merchantId;
                eventData.merchant_name = merchantName;
                eventData.user_id = userId;
                eventData.mobile_no = mobile;
                eventData.plan_shown = plan?.tenure;
                eventData.page_shown = currentPage;
            }
            captureEvent(EventName.PLAN_CHANGE_COMPONENT_CLICKED, eventData, true);
        }
    };

    const updateNewPlan = (plan: Plan, variableDp: any, newPublicPlan = false) => {
        fetchUserPlan(checkoutId ? checkoutId : '', plan, variableDp);
        setPlan(plan);
        togglePlanSelector();
        if (plan.tenure === 1) {
            setPayLater(true);
        } else {
            setPayLater(false);
        }
        if (updateCheckPayLater) {
            updateCheckPayLater(plan.tenure === 1);
        }
        if (newPublicPlan) setLocalStorageKey(`default_plan_tenure_${checkoutId}`, JSON.stringify(plan.tenure));
    };

    useEffect(() => {
        try {
            const _orderValue = Number(orderValue);
            setOrderVal(_orderValue);
        } catch (err) {
            console.log(err);
        }
    }, [orderValue]);

    const { finalDp, finalEmi, totalCashBack, totalCashbackRounded } = divideCashBack(
        plan?.tdp,
        plan?.emiAmount,
        plan?.tenure
    );

    const isPublicpage = ['/login', '/otp', '/otp-bypass','/otp-bypass-confirm'].includes(window.location.pathname);

    return (
        <>
            {isCashBackEnabled && showCashBack && <CashbackComponent />}
            <SnapCard className="bg-white flex flex-col items-center px-[12px] py-0 mx-0 w-[100%] font-interMedium mb-[16px]">
                {plan && (isPublicpage || cashBackDetailsLoaded) && (
                    <>
                        <div className="flex items-center gap-8 width-full bg-[#F2F2F2] px-[16px] mx-[16px] py-[4px] rounded-b-lg">
                            <>
                                {merchantName && merchantName.length > 0 && (
                                    <>
                                        <Medium8Text
                                            text={`${merchantName}`}
                                            className="text-[#3D494B] truncate  !font-robotoMedium"
                                        />
                                        <div className="h-4 w-[1px] bg-lineBreak " />
                                    </>
                                )}
                                <>
                                    <Medium8Text
                                        text={`Order Total: ₹${orderVal.toFixed(0)}`}
                                        className="truncate !font-robotoMedium !text-[#3d494b]"
                                    />
                                </>
                            </>
                        </div>
                        <div
                            className={`flex items-start justify-between mt-[1px] gap-6 sm:w-full sm:gap-6 xs:gap-4 ${
                                planList && planList.length == 1 ? 'pt-[8.5px]' : ''
                            }`}
                        >
                            <>
                                <div className='flex items-center gap-6'>
                                    <div className="flex flex-col items-center min-w-fit mb-[15px]">
                                        <Bold13Text
                                            text="Pay Now"
                                            className="text-[#657173] pt-[4px] !font-robotoBold"
                                        />

                                        <Bold12Text
                                            text={`₹ ${finalDp.toFixed(0)}`}
                                            className="text-[#3D494B] !font-robotoBold"
                                        />

                                        <Medium10Text
                                            text="Downpayment"
                                            className="text-[#657173] !font-robotoMedium"
                                        />
                                    </div>
                                    <img
                                        src={`${BASE_PATH}/assets/images/plus_sign.svg`}
                                        className="w-[25.45px] h-[24px] mb-[12px]"
                                        alt=""
                                    />
                                </div>
                                <div className='flex items-center gap-6'>
                                    <div
                                        className={`flex flex-col items-center min-w-fit ${
                                            planList && planList.length > 1 ? 'pt-[5px]' : 'pb-[12px]'
                                        } ${checkIsPayLater ? 'mb-[28px] relative w-[82px]' : ''} 
                                        ${checkIsPayLater && planList.length === 1 ? '!mb-[12px]' : ''}`}
                                    >
                                        <Bold13Text
                                            text={emiOrPayLaterText}
                                            className="text-[#657173] !text-[13px] !font-robotoBold"
                                        />

                                        <Bold12Text
                                            text={`₹ ${finalEmi.toFixed(0)}`}
                                            className="text-[#3D494B] !text-[12px] !font-robotoBold"
                                        />

                                        <Medium10Text
                                            text={`x${plan.tenure} months`}
                                            className={`text-[#657173] !text-[10px] !font-robotoMedium ${
                                                checkIsPayLater ? 'hidden' : ''
                                            } `}
                                        />

                                        {planList && planList.length > 1 && (
                                            <div
                                                onClick={togglePlanSelector}
                                                className={`${
                                                    checkIsPayLater ? 'mt-4 absolute top-[38px] left-[-20px] w-max' : ''
                                                } flex items-center justify-between text-snapBlue text-xs font-semibold gap-[2px] h-[18px] px-[4px] border border-[#70C0CC] rounded-[4px] cursor-pointer`}
                                            >
                                                {checkIsPayLater ? (
                                                    <Bold10Text
                                                        text={`EMI Payment Options`}
                                                        className="text-[#70C0CC] !font-robotoBold cursor-pointer"
                                                    />
                                                ) : planList?.filter((plan) => plan.tenure === 1).length ? (
                                                    <Bold10Text
                                                        text={'Pay Later Option'}
                                                        className="text-[#70C0CC] !font-robotoBold cursor-pointer"
                                                    />
                                                ) : (
                                                    <Bold10Text
                                                        text={`${planList
                                                            .map((plan) => {
                                                                return plan.tenure;
                                                            })
                                                            .join('/')} EMI Plans`}
                                                        className="text-[#70C0CC] !font-robotoBold cursor-pointer"
                                                    />
                                                )}
                                                <img
                                                    src="assets/images/edit-pencil.svg"
                                                    alt=""
                                                    className="w-[10px] h-[10px] fill-red"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <img
                                        src={`${BASE_PATH}/assets/images/arrow_right.svg`}
                                        className="w-[25.45px] h-[24px] mb-[12px]"
                                        alt=""
                                    />
                                </div>
                                <>
                                    <div
                                        className={`flex flex-col items-center min-w-fit ${
                                            !isCashBackUsed && 'mb-[8px]'
                                        }`}
                                    >
                                        <Bold13Text text="Total" className="text-[#657173] !font-robotoBold" />

                                        <Bold12Text
                                            text={`₹ ${(plan.totalYouPay - totalCashBack).toFixed(0)}`}
                                            className="text-[#3D494B] !font-robotoBold"
                                        />
                                        {isCashBackUsed && (
                                            <Medium10Text
                                                text={`₹ ${plan.totalYouPay.toFixed(0)}`}
                                                className="text-[#657173] font-robotoMedium line-through"
                                            />
                                        )}

                                        <SnapBadge
                                            className={`rounded-[100px] h-[16px] flex items-center justify-center !bg-[#AFE137] ${
                                                plan && plan.isZeroPercent ? '' : 'invisible'
                                            }`}
                                        >
                                            <Bold7Text
                                                text={snapBadgeText}
                                                className="!text-[#014751] !font-robotoBold !text-[9px]"
                                            ></Bold7Text>
                                        </SnapBadge>
                                    </div>
                                </>
                            </>
                        </div>

                        <div className="flex items-center text-tiny justify-between mt-[4px] mb-[6px] gap-2">
                            <img
                                src={`${BASE_PATH}/assets/images/snapmint_green_small_logo.svg`}
                                alt=""
                                className="w-[18px] h-[16.76px]"
                            />
                            <Medium10Text
                                text={snapAppText}
                                className="text-[#90A4A7] leading-[13px] !font-robotoMedium"
                            />
                            <span className="bg-[#90A4A7] w-2 h-2 rounded-lg" />
                            {plan && (
                                <div className="!font-robotoMedium !text-[#90A4A7] text-[10px] leading-[13px]">
                                    {new Date(plan.emiStartDate).toLocaleDateString('en-US', { day: 'numeric' })}
                                    <sup>rd </sup>
                                    {new Date(plan.emiStartDate).toLocaleDateString('en-US', {
                                        month: 'short',
                                        year: '2-digit'
                                    })}{' '}
                                    {!checkIsPayLater ? (
                                        <>
                                            -{' '}
                                            {new Date(plan.emiEndDate).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: '2-digit'
                                            })}
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        <CashbackRedeem totalCashBack={totalCashbackRounded} />
                    </>
                )}
                {(!plan || (!isPublicpage && !cashBackDetailsLoaded)) && <SnapShimmer className="w-[100%] h-[132px] mt-[0px]" />}
            </SnapCard>
            {openPlanSelector && plan && planOptionShow && (
                <PopUpModal
                    isOpen={openPlanSelector}
                    onClose={togglePlanSelector}
                    children={<PublicPlanSelectionComponent2 onSelect={updateNewPlan} plan={plan} />}
                />
            )}
            {openPlanSelector && plan && !planOptionShow && (
                <PopUpModal
                    isOpen={openPlanSelector}
                    onClose={togglePlanSelector}
                    children={<PublicPlanSelectionComponent onSelect={updateNewPlan} plan={plan}/>}
                />
            )}
        </>
    );
};

interface UnleashProperties {
    [key: string]: string;
}

export class UnleashConfigClient {
    unleashConfig = {
        url: process.env.REACT_APP_UNLEASH_URL as string,
        clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY as string,
        appName: 'checkout-client',
        context: {
            properties: {}
        },
        disableRefresh: true
    };

    getConfig(properties: UnleashProperties) {
        this.unleashConfig.context.properties = {
            ...this.unleashConfig.context.properties,
            ...properties
        };
        return this.unleashConfig;
    }
}

export function getUnleashConfig(merchant_id: string, userId: string, email_or_mobile: string, mobile?: string) {
    const unleashConfigClient = new UnleashConfigClient();
    const unleashConfig = unleashConfigClient.getConfig({
        merchant_id: merchant_id,
        userId: userId,
        email_or_mobile: email_or_mobile,
        user_id: userId,
        ...(mobile && { mobile: mobile })
    });
    return unleashConfig;
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserService } from "../../service/user.service";
import { useUrlParamParser } from "../util/url-param-parse.hook";
import { useRedirectionHelper } from "../util/use-redirection.hook";

export const useRouteValidation = () => {
    const transactionParamModel = useUrlParamParser()
    const { state }: any = useLocation();
    const { checkRedirection } = useRedirectionHelper()

    const [_state, setState] = useState(state)

    const fetchState = async () => {
        let id = transactionParamModel?.checkoutId ? transactionParamModel?.checkoutId : ""
        const res = await UserService.moveNext(id, transactionParamModel)
        checkRedirection(res.data)
    }

    useEffect(() => {
        if (transactionParamModel.flowType !== 'eligibility') {
            if (!state || state.checkoutRedirection != true) {
                fetchState()
            } else {
                setState(state)
            }
        }
    }, [state])

    return { state: _state }
}
import { useRef, useEffect, useState } from "react";

interface IUseInterval {
    (callback: () => void, start: boolean, interval: number):  { stopInterval: () => void };
}

export const useInterval: IUseInterval = (callback, start, interval): { stopInterval: () => void } => {
    const savedCallback = useRef<(() => void) | null>(null);
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
    // After every render, save the latest callback into our ref.
    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {

        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (start) {
            const intervalId = setInterval(tick, interval);
            setIntervalId(intervalId);
            return () => clearInterval(intervalId);
        }
    }, [start, interval]);

    const stopInterval = () => {
        clearInterval(intervalId);
    };
    return {
        stopInterval
    };
};

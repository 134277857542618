const _window = window as any;
const Android = (_window as any).Android;

export const NativeBridge = {
    checkForNative: async (): Promise<string> => {
        try {
            if (Android) {
                return String(Android.checkForNative());
            } else if (_window) {
                const data = await _window.webkit?.messageHandlers['snapNativeHandler'].postMessage({
                    methodName: 'checkForNative',
                    parameters: {}
                });
                return data?data:'';
            }
        } catch {
            return '';
        }
        return '';
    },
    closeWebView: async () => {
        try {
            if (Android) {
                Android.closeWebView();
            } else if (_window) {
                await _window.webkit?.messageHandlers['snapNativeHandler'].postMessage({
                    methodName: 'closeWebView',
                    parameters: {}
                });
            }
        } catch {
            
        }
    },
    orderSuccess: async (orderData: any) => {
        try {
            if (Android) {
                Android.orderSuccess(JSON.stringify(orderData));
            } else if (_window) {
                _window.webkit?.messageHandlers['snapNativeHandler'].postMessage({
                    methodName: 'orderSuccess',
                    parameters: {
                        orderData: orderData
                    }
                });
            }
        } catch {}
    }
};

import Axios from "axios"

export const useHttpIntercepter = () => {

    // Axios.defaults.withCredentials = true

    Axios.interceptors.request.use(config => {
        return config
    })

    Axios.interceptors.response.use((response: any) => {
        // if(response && response.data && response.data.redirectUrl && response.data.redirectUrl.length > 0) {
        //     window.location.href = response.data.redirectUrl;
        //     return
        // }
        // if (response.request.responseURL && response.request.responseURL.length > 0) {
        //     window.location.href = response.request.responseURL;
        //     return Promise.all("")
        // }
        return response
    }, error => {
        // if (error.response.status == 404 && error.request.responseURL && error.request.responseURL.length > 0) {
        //     window.location.href = error.request.responseURL;
        //     return Promise.all("")
        // }
        return Promise.reject(error)
    })
}
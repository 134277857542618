import { getLocalStorageKey, removeLocalStoregaeKey, setLocalStorageKey } from './local-stoarge.helper';

export const isPayLater = () => {
    const payLater = getLocalStorageKey('isPayLater');
    if (payLater) {
        return true;
    } else {
        return false;
    }
};

export const setPayLater = (isPayLater: boolean) => {
    if (isPayLater) {
        setLocalStorageKey('isPayLater', 'true');
    } else {
        removeLocalStoregaeKey('isPayLater');
    }
};
import { Medium12Text, SnapCard } from '@snapmint/ui-components';
import { BASE_PATH } from '../../enum/base.enum';
import { useEffect, useState } from 'react';
import { PlanService } from '../../service/plan.service';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';

export const CashbackComponent = () => {
    const [couponData, setCouponData]: any = useState(null);
    const _transactionParamModel = useUrlParamParser();
    useEffect(() => {
        const { checkoutId }: any = _transactionParamModel;
        if (!checkoutId) return;
        PlanService?.getCouponDetails(checkoutId)
            .then((res: any) => {
                setCouponData(res.data);
            })
            .catch((err: any) => {
                console.log(err);
            });
    }, []);
    return (
        <>
            {couponData?.isCouponApplicable && couponData?.couponDetails?.promoType === 'cashback' && (
                <SnapCard className="flex bg-white flex-col items-center px-[12px] py-0 mx-0 w-[100%] font-interMedium mb-[16px] py-[18px]">
                    <div className="flex">
                        <div className="flex items-center">
                            <img src={`${BASE_PATH}/assets/images/rupee-bag.svg`} />
                            <span className="pl-[12px] text-[12px] pr-[12px] border-r border-[#F1F1F1]">
                                {couponData?.couponDetails?.savingText?.split('savings')?.length > 1 && (
                                    <>
                                        <span className="font-extrabold">
                                            {couponData?.couponDetails?.savingText?.split('savings')[0] + ' savings'}
                                        </span>
                                        <span className="font-bold">
                                            {couponData?.couponDetails?.savingText?.split('savings')[1]}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="flex justify-around w-[60%]">
                            <div>
                                {couponData?.couponDetails?.couponData?.map((text: any, index: number) => (
                                    <>
                                        <Medium12Text
                                            className={index === 1 ? 'font-semibold' : ''}
                                            text={text?.labelText}
                                        ></Medium12Text>
                                    </>
                                ))}
                            </div>
                            <div>
                                {couponData?.couponDetails?.couponData?.map((text: any, index: number) => (
                                    <>
                                        <Medium12Text
                                            className={index === 1 ? 'font-semibold' : ''}
                                            text={`: ${text?.valueText} `}
                                        ></Medium12Text>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </SnapCard>
            )}
            {couponData?.isCouponApplicable && couponData?.couponDetails?.promoType === 'instant_discount' && (
                <SnapCard className="flex bg-white flex-col items-center px-[12px] py-0 mx-0 w-[100%] font-interMedium mb-[16px] py-[18px]">
                    <div className="flex">
                        <div className="flex items-center">
                            <img src={`${BASE_PATH}/assets/images/thumbs_up.svg`} className="max-w-[28px]" />
                            <span className="pl-[12px] text-[12px] border-r border-[#F1F1F1] font-bold">
                                {couponData?.couponDetails?.savingText?.split('savings')?.length > 1 && (
                                    <>
                                        <span className="font-extrabold">
                                            {couponData?.couponDetails?.savingText?.split('savings')[0] + ' savings'}
                                        </span>
                                        <span className="font-bold">
                                            {couponData?.couponDetails?.savingText?.split('savings')[1]}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="flex justify-around w-[60%]">
                            <div>
                                {couponData?.couponDetails?.couponData?.map((text: any, index: number) => (
                                    <>
                                        <Medium12Text text={text?.labelText}></Medium12Text>
                                    </>
                                ))}
                            </div>
                            <div>
                                {couponData?.couponDetails?.couponData?.map((text: any, index: number) => (
                                    <>
                                        <Medium12Text text={`: ${text?.valueText} `}></Medium12Text>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </SnapCard>
            )}
        </>
    );
};

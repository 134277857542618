export enum EventName {
    MOBILENUMBER_PV = 'mobilenumber_pv',
    ORDER_SUCCESS_PV = 'order_success_pv',
    OTP_PV = 'otp_pv',
    USERDETAIL_PV = 'userdetail_pv',
    USER_NAME_PV = 'user_name_pv',
    DELIVERYADRESS_PV = 'deliveryaddress_pv',
    PERFIOS_PV = 'perfios_pv',
    PLANSCREEN_PV = 'planscreen_pv',
    DIGILOCKERKYC_PV = 'digilockerkyc_pv',
    MANUALKYC_PV = 'manualkyc_pv',
    MANUALKYC_SUBMITTED = 'manualkyc_submitted',
    DIGILOCKERKYC_SUBMITTED = 'digilockerkyc_submitted',
    KYC_SELFIE_OR_ALTERNATE_NO_PV = 'kyc_selfie_or_alernateno_pv',
    UPI_SI_PV = 'upi_si_pv',
    ALTERNATE_NUMBER_SUBMITTED = 'alternate_number_submitted',
    SELFIE_PV = 'selfie_pv',
    SELFIE_SUBMITTED = 'selfie_submitted',
    ALTERNATE_NO_PV = 'alternate_no_pv',
    NET_BANKING_SI_PV = 'netbanking_si_pv',
    CONFIRM_PAGE_PV = 'confirm_page_view',
    PG_PV = 'pg_pv',
    ORDERSUCCESS_PV = 'ordersuccess_pv',
    PERFIOS_SUBMITTED = 'perfios_submitted',
    NACH_SUBMITTED = 'nach_submitted',

    PERFIOS_CTA_CLICK = 'perfios_cta_click',
    PERFIOS_ON_BANK_SELECTION = 'perfios_on_bank_selection',

    NACH_NETBANKING_CTA_CLICK = 'netbanking_cta_click',

    PG_UPI_COLLECT_CLICKED = 'upi_collect_clicked',
    PG_UPI_INTENT_CLICKED = 'upi_intent_clicked',
    PG_CARD_METHOD_CLICKED = 'pg_card_method_clicked',
    PG_NETBANKING_CLICKED = 'pg_netbanking_clicked',
    PG_BANK_SELECTED = 'pg_nb_bank_selected',

    PG_PAYMENT_SUBMITTED = 'payment_submitted',

    OTP_SUBMITTED = 'otp_submitted',
    OTP_RESEND_CLICKED = 'otp_resend_clicked',
    OTP_VERIFY_BY_CALL_CLICKED = 'otp_verify_by_call_clicked',

    UI_PG_UPI_INTENT_CLICK = 'ui_pg_upi_intent_click',
    UI_PG_MENU_CLICK = 'ui_pg_menu_click',
    UI_PG_ERROR_FOUND = 'ui_pg_error_found',

    TXN_FAILED_ERROR = 'txn_failed_error',
    CHECKOUT_EMI_PLANS = 'checkout_emi_plans',
    COOKIE_BLOCKED_PV = 'cookie_blocked_pv',
    KYC_FAILED_PV = 'kyc_failed_pv',
    PERFIOS_FAILED_PV = 'perfios_failed_pv',
    VDP_PLANSSCREEN_PV = 'vdp_planscreen_pv',
    NACH_FAILED_PV = 'nach_failed_pv',
    PG_FAILED_PV = 'pg_failed_pv',
    SUCCESSFUL_REDIRECTION = 'successful_redirection',
    PAYMENT_SUBMITTED = 'payment_submitted',
    DELIVERY_ADDRESS_SUBMITTED = 'delivery_address_submitted',
    USER_DETAIL_PAGE_SUBMITTED = 'user_detail_page_submitted',
    KYC_PV_SUBMITTED = 'kyc_pv_submitted',
    ERROR_PAGE_PV = 'error_page_pv',

    OTP_VERIFIED = 'otp_verified',
    NACH_PV = 'nach_pv',
    NACH_PV_BANK_SELECTED = 'nach_pv_bank_selected',
    NACH_PV_SUBMIT_CLICK = 'nach_pv_submit_click',
    NB_TAB_CLICKED = 'nb_tab_clicked',
    CONFIRMEMI_CLICKED = 'confirmemi_clicked',
    ENTER_VPA_HANDLE_CLICKED = 'enter_vpa_handle_clicked',
    PHONEPE_UPI_CLICKED = 'phonpe_upi_clicked',
    PAYTM_UPI_CLICKED = 'paytm_upi_clicked',
    GOOGLEPAY_UPI_CLICKED = 'gpay_upi_clicked',
    OTHER_UPI_CLICKED = 'other_upi_clicked',
    LANDED_ON_CHECKOUT = 'landed_on_checkout',
    LANDED_ON_WEB_VIEW_CHECKOUT = 'landed_on_web_view_checkout',

    KYC_PV = 'kyc_pv',
    KYC_COMPLETED = 'kyc_completed',
    KYC_REDIRECT_SUCCESS = 'kyc_redirect_success',
    HYPERVERGE_SUCCESS = 'hypervergesuccess',
    HYPERVERGE_FAILURE = 'hypervergefailure',

    CUSTOM_AIA_DP_PAGE_VISITED = 'Custom_AIA_DP_page_visited',
    CUSTOM_AIA_DP_GIVEN = 'Custom_AIA_DP_given',
    CUSTOM_AIA_DP_FAILED = 'Custom_AIA_DP_failed',
    CUSTOM_AIA_MERCHANT_SUCCESS_SCREEN = 'Custom_AIA_Merchant_Success_Screen',
    CHECKOUT_AB_CAPTURE = 'checkout_ab_capture',
    OTP_ERROR = 'otp_error',
    OTP_AUTOREAD_REQUESTED = 'otp_autoread_requested',
    OTP_AUTOREAD_SUBMITTED = 'otp_autoread_submitted',
    VERIFY_ADHAR_OTP_BUTTON = 'verify_adhar_otp_button',
    OTP_RESEND_OPTIONS_VIEWED = 'otp_resend_options_viewed',
    OTP_RESEND_OPTION_CLICKED = 'otp_resend_option_clicked',
    SMS_SERVICE_SUCCESSFUL_CALL = 'sms_service_successful_call',
    WHATSAPP_SERVICE_SUCCESSFUL_CALL = 'whatsapp_service_successful_call',
    DEBIT_CARD_NACH = 'debit_card_nach',
    OTP_TNC_CLICK = 'otp_tnc_click',
    PLAN_CHANGE_COMPONENT_CLICKED = 'plan_change_component_clicked',
    PLAN_CHANGE_OPTION_SELECTED = 'plan_change_option_selected',
    FAILURE_PV = 'failure_pv',
    KYC_ALTERED_NO_PV = 'kyc_altered_no_pv',
    KYC_SELFIE_PV = 'kyc_selfie_pv',
    NEW_CHECKOUT = 'new_checkout',
    SUPER_CHECKOUT = 'super_checkout',
    CHECKOUT_USER_CHANNEL = 'checkout_user_channel',
    CHECKOUT_CLARITY_BASIC_PROPERTIES = 'checkout_clarity_basic_properties',
    UPI_SI_FAIL = 'upi_si_fail',
    CASHBACK_TOGGLE_CLICKED = 'cashback_toggle_clicked',
    CASHBACK_TOGGLE_VISIBLE = 'cashback_toggle_visible',
    ORDER_CONFIRM_PAGE_PV = 'confirmpage_pv',
    UPI_SI_ATTEMPTED = 'upi_si_attempted',
    UPI_OPTION_CLICKED = 'upi_option_clicked',
    ASHIELD_INIT = 'ashield_init',
    ASHIELD_API = 'ashield_api',
    TNC_PV = 'tnc_pv',
    TNC_CONSENT_CLICKED = 'tnc_consent_clicked'
}

export enum PlEvents {
    PL_KYC_DL_CLICK = 'pl_kyc_digilocker_clicked',
    PL_KYC_MT_CLICK = 'pl_web_kyc_manualtab_clicked',
    PL_KYC_MT_SEL_CLICK = 'pl_web_kyc_manualtab_selfie_clicked',
    PL_KYC_MT_AF_CLICK = 'pl_web_kyc_manualtab_aadharF_clicked',
    PL_KYC_MT_AB_CLICK = 'pl_web_kyc_manualtab_aadharB_clicked',
    PL_KYC_WEB_NEXT_CLICK = 'pl_web_kyc_next_clicked',

    PL_WEB_BD_SHOWN = 'pl_web_bankdetailshown_pv',
    PL_WEB_BD_CLICK = 'pl_web_bankdetails_clicked',
    PL_WEB_ADD_BK_CLICK = 'pl_web_addnewbank_clicked',
    PL_MITC_CLICK = 'pl_web_mitc_clicked',
    PL_BNK_SHWN_VRFY_CLICK = 'pl_web_bankshown_verifycta_clicked',

    PL_ADD_BNK_PV = 'pl_web_addbank_nach_pv',
    PL_WEB_NCH_VIA_CLICK = 'pl_web_nach_via_click',

    PL_CNFRM_LOAN_PV = 'pl_web_confirmloan_pv',
    PL_WEB_CNFRM_LN_MITC_CLICK = 'pl_web_confirmloan_mitc_clicked',
    PL_WEB_CNFRM_CTA_CLICK = 'pl_web_confirmloan_cta_clicked',

    PL_WEB_MITC_POPUP_PV = 'pl_web_mitc_popup_pv',
    PL_WEB_MITC_YES_CLICK = 'pl_web_mitc_yes_clicked',
    PL_WEB_MITC_NO_CLICK = 'pl_web_mitc_no_clicked'
}

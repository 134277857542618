import { useEffect, useState } from 'react';
import { Plan } from '../../response/plan.res';
import { PlanService } from '../../service/plan.service';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';
import { getLocalStorageKey, setLocalStorageKey } from '../../utils/local-stoarge.helper';
import { setPayLater } from '../../utils/pay-later.helper';
import { PlanV2 } from '../../response/plan-v2.res';

export const usePublicPlan = () => {
    const [planList, setPlanList] = useState<Plan[]>([]);

    const fetchUserPlan = async (checkoutId: string, plan?: Plan, variableDp?: any) => {
        const planList: any = getLocalStorageKey(`public_plan_${checkoutId}`);
        if (planList && planList.length > 10 && !plan && !variableDp) {
            setPlanList(JSON.parse(planList));
        } else {
            const startTime = new Date().getTime();
            const res = await PlanService.getPublicPlan(checkoutId, { plan: plan, variableDp: variableDp });
            const endTime = new Date().getTime();
            captureEvent(EventName.CHECKOUT_EMI_PLANS, {
                initiated_time: startTime,
                response_time: endTime,
                cart_id: checkoutId,
                is_public: true
            });
            setLocalStorageKey(`public_plan_${checkoutId}`, JSON.stringify(res.data.plans));
            if (res.data.variableDpOptions) {
                setLocalStorageKey(
                    `public_variableDpOptions_${checkoutId}`,
                    JSON.stringify(res.data.variableDpOptions)
                );
            }
            let defaultPlanTenure = getLocalStorageKey(`default_plan_tenure_${checkoutId}`);
            if (defaultPlanTenure) {
                defaultPlanTenure = JSON.parse(defaultPlanTenure);
                setLocalStorageKey(`default_plan_tenure_${checkoutId}`, JSON.stringify(defaultPlanTenure));
            } else {
                if (res.data.plans && res.data.plans.length > 0 && res.data.plans[0]?.tenure)
                    setLocalStorageKey(`default_plan_tenure_${checkoutId}`, JSON.stringify(res.data.plans[0]?.tenure));
            }
            setPlanList(res.data.plans);
        }
    };

    return { planList, fetchUserPlan };
};

export const useFinalPlan = () => {
    const transactionParamModel = useUrlParamParser();
    const [plan, setPlan] = useState<Plan>();

    const { checkoutId } = transactionParamModel;

    useEffect(() => {
        if (checkoutId && checkoutId.length > 0 && !plan) {
            fetchUserPlan(checkoutId);
        }
    }, [checkoutId]);

    const fetchUserPlan = async (checkoutId: string, plan?: Plan, variableDp?: any) => {
        // const _plan: any = window.localStorage.getItem(`user_plan_data${checkoutId}`)
        // if (_plan && _plan.length > 10 && !plan && !variableDp) {
        //     setPlan(JSON.parse(_plan))
        // } else {
        const startTime = new Date().getTime();
        const res = await PlanService.getUserPlanData(checkoutId, { plan: plan, variableDp: variableDp });
        if (res?.data) {
            if (res?.data?.tenure === 1) {
                setPayLater(true);
            } else {
                setPayLater(false);
            }
        }
        const endTime = new Date().getTime();
        captureEvent(EventName.CHECKOUT_EMI_PLANS, {
            initiated_time: startTime,
            response_time: endTime,
            cart_id: checkoutId,
            is_public: false
        });
        setLocalStorageKey(`user_plan_data${checkoutId}`, JSON.stringify(res.data));

        const _plan = res.data;
        if (!_plan.tdp && _plan.dp) {
            _plan.tdp = _plan.dp;
        }
        if (!_plan.emiAmount && _plan.emi) {
            _plan.emiAmount = _plan.emi;
        }
        if (!_plan.emiStartDate && _plan.startAt) {
            _plan.emiStartDate = _plan.startAt;
            _plan.emiEndDate = _plan.endAt;
        }

        setPlan(_plan);
        // }
    };

    return { plan };
};

export const useUserPlans = () => {
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState<PlanV2>({} as PlanV2)

    const transactionParamModel = useUrlParamParser();

    const { checkoutId } = transactionParamModel;

    useEffect(() => {
        fetchUserPlans();
    }, []);

    const fetchUserPlans = async () => {
        setLoading(true);
        try {
            const userSelectedPlanRes = await PlanService.getUserPlanData(checkoutId as string, {});
            setSelectedPlan(userSelectedPlanRes.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    return {
        loading,
        selectedPlan
    };
};

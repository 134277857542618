import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AppStateProvider } from './app.context';
import FlagProvider from '@unleash/proxy-client-react';
import { useUrlParamParser } from './page/util/url-param-parse.hook';
import { getUnleashConfig } from './utils/unleash';
import ErrorBoundary from './ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const RenderElement = () => {
    const transactionParamModel = useUrlParamParser();
    const { merchantId, userId, mobile } = transactionParamModel;
    const unleashConfig = getUnleashConfig(merchantId, userId as string, mobile, mobile);
    return (
        <ErrorBoundary>
            <BrowserRouter basename="">
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <FlagProvider config={unleashConfig}>
                        <AppStateProvider>
                            <App />
                        </AppStateProvider>
                    </FlagProvider>
                </QueryParamProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

root.render(<RenderElement />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

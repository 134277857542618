import Axios, { AxiosResponse } from "axios";
import { BASE_API_PATH } from "../enum/base.enum";
import { PlanReq } from "../request/plan.req";
import { PlanRes } from "../response/plan.res";

export const PlanService = {
    getPublicPlan(checkoutId: string, planReq: PlanReq): Promise<AxiosResponse<PlanRes>> {
        return Axios.post(`${BASE_API_PATH}/plans/public?checkoutId=${checkoutId}`, planReq)
    },
    getUserPlan(checkoutId: string, planReq: PlanReq): Promise<AxiosResponse<any>> {
        return Axios.post(`${BASE_API_PATH}/plans?checkoutId=${checkoutId}`, planReq)
    },
    getUserPlanData(checkoutId: string, planReq: PlanReq): Promise<AxiosResponse<any>> {
        return Axios.post(`${BASE_API_PATH}/plans/user-plan-data?checkoutId=${checkoutId}`, planReq)
    },
    getUserPlanV2(checkoutId: string): Promise<AxiosResponse<any>> {
        return Axios.post(`${BASE_API_PATH}/v2/plans?checkoutId=${checkoutId}`)
    },
    getCouponDetails(checkoutId:string): Promise<AxiosResponse<any>> {
        return Axios.get(`${BASE_API_PATH}/coupons?checkoutId=${checkoutId}`)
    }
}
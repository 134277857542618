import { useEffect, useState } from "react"
import { MobileNumberComponent } from "../../component/mobile-number/mobile-number.component"
import { OrderDetailComponent } from "../../component/order-detail/order-detail.component"
import { SendOTPReq } from "../../request/login.req"
import { UserService } from "../../service/user.service"
import { useUrlParamParser } from "../util/url-param-parse.hook"
import { useRedirectionHelper } from "../util/use-redirection.hook"
import { PreEmiLoaderComponent } from "../../component/loader/pre-emi-loader.component"
import { useLocation } from "react-router-dom"
import { EventName } from "../../events/event-name.enum"
import { captureEvent } from "../../events/event.helper"
import { OrderDetailAfterPlanComponent } from "../../component/order-detail/order-detail-after-plan.component"
import { OrderDetailSuperComponent } from "../../component/order-detail/order-detail-super-component"
import { AxiosError } from "axios"

const MobilePage = () => {
  const transactionParamModel = useUrlParamParser()
  const { checkRedirection } = useRedirectionHelper()

  const { state }: any = useLocation();

  const [loading, setLoading] = useState(false)
  const [error , setError] = useState("");

  const sendOTP = async (sendOTPReq: SendOTPReq) => {
    setLoading(true);
    
        try {
            const res = await UserService.sendOTP(
                transactionParamModel.checkoutId ? transactionParamModel.checkoutId : '',
                sendOTPReq
            );
            checkRedirection(res.data);
        } catch (error) {
            if (error instanceof AxiosError) {
              const message = error && error.response && error.response.data.redirect && error.response.data.redirect.message;
              setError(message)
              checkRedirection(error?.response?.data);
            }
        } finally {
            setLoading(false);
        }
  }

  useEffect(() => {
    let eventDataObj: any = {}
        if (transactionParamModel) {
      const { checkoutId, merchantId, merchantName, userId, mobile } = transactionParamModel
      eventDataObj.cart_id = checkoutId
      eventDataObj.merchant_id = merchantId
      eventDataObj.merchant_name = merchantName
      eventDataObj.user_id = userId
      eventDataObj.mobile_no = mobile
    }

    eventDataObj.user_channel = "online_mobile"
    captureEvent(EventName.MOBILENUMBER_PV, eventDataObj, true, true)
    captureEvent(EventName.CHECKOUT_USER_CHANNEL, {}, false , true , {checkout_user_channel: "online mobile"});

    
  }, [])

  const { emiPlanId } = transactionParamModel;

  return (
    <div className="pt-[8px] px-[16px] max-w-[374px] mx-auto">
      <OrderDetailSuperComponent />
      {!loading && <MobileNumberComponent submitNumber={sendOTP} error={error} setError={setError} />}
      {loading && (
        <PreEmiLoaderComponent />
      )}
    </div>
  )
}

export default MobilePage

import { useEffect } from "react"
import { SeonReq } from "../../request/fraud.req"
import { FraudService } from "../../service/fraud.service"
import { useQueryParam, StringParam } from 'use-query-params';

export const useSeon = () => {
    const [loanId, setLoanId] = useQueryParam('loanId', StringParam);
    const [checkoutId, setCheckoutId] = useQueryParam('checkoutId', StringParam);

    useEffect(() => {
        if (loanId && loanId != "undefined" && loanId.length > 0) {
            const seon = (window as any).seon
            if (typeof seon !== "undefined" && seon !== null) {
                seon.config({
                    host: "snapmint.com",
                    session_id: loanId,
                    audio_fingerprint: true,
                    canvas_fingerprint: true,
                    webgl_fingerprint: true,
                    onSuccess: (message: any) => {
                        seon.getBase64Session(async (data: any) => {
                            if (data) {
                                await FraudService.updateSeonData(checkoutId ? checkoutId : "", {
                                    sessionId: data
                                } as SeonReq)
                            } else {
                                console.log("Failed to retrieve session data.");
                            }
                        });
                    },
                    onError: (message: any) => {
                        console.log("error", message);
                    }
                });
            }
        }
    }, [loanId])
}
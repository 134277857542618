import { BASE_PATH } from "../../enum/base.enum"

export const FooterData = [
  {
    imgLogo: `${BASE_PATH}/assets/images/snapmint-logo.svg`,
    text: "1 Crore + Users",
    imgStar: `${BASE_PATH}/assets/images/google-pay-stars.svg`,
  },
  {
    imgLogo: `${BASE_PATH}/assets/images/google-pay.svg`,
    text: "Available on Google Pay",
  },
  {
    imgLogo: `${BASE_PATH}/assets/images/reserve-bank.svg`,
    text: "RBI License No. N-13.02352",
  },
]

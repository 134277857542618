function a0_0x272d(_0x3ed7fe, _0x573727) {
    const _0x1b35b6 = a0_0x1b35();
    return (
        (a0_0x272d = function (_0x272d61, _0x54b66f) {
            _0x272d61 = _0x272d61 - 0x93;
            let _0x5ec159 = _0x1b35b6[_0x272d61];
            return _0x5ec159;
        }),
        a0_0x272d(_0x3ed7fe, _0x573727)
    );
}
const a0_0x360d85 = a0_0x272d;
(function (_0x4b2428, _0x310ba9) {
    const _0x5cb78a = a0_0x272d,
        _0x4a76aa = _0x4b2428();
    while (!![]) {
        try {
            const _0x40e8a3 =
                parseInt(_0x5cb78a(0xb8)) / 0x1 +
                -parseInt(_0x5cb78a(0xaf)) / 0x2 +
                parseInt(_0x5cb78a(0x99)) / 0x3 +
                (parseInt(_0x5cb78a(0xd8)) / 0x4) * (-parseInt(_0x5cb78a(0xdc)) / 0x5) +
                (parseInt(_0x5cb78a(0xc9)) / 0x6) * (parseInt(_0x5cb78a(0x94)) / 0x7) +
                (-parseInt(_0x5cb78a(0x9d)) / 0x8) * (parseInt(_0x5cb78a(0xb7)) / 0x9) +
                parseInt(_0x5cb78a(0xe5)) / 0xa;
            if (_0x40e8a3 === _0x310ba9) break;
            else _0x4a76aa['push'](_0x4a76aa['shift']());
        } catch (_0x4d91bb) {
            _0x4a76aa['push'](_0x4a76aa['shift']());
        }
    }
})(a0_0x1b35, 0x77008);
const logger = console;
function arrayBufferToString(_0x5163f6) {
    const _0x4b9aee = a0_0x272d;
    return String[_0x4b9aee(0xd4)][_0x4b9aee(0xa0)](null, new Uint8Array(_0x5163f6));
}
function toArrayBuffer(_0x3f310a) {
    const _0x3425ad = a0_0x272d,
        _0x430013 = _0x3f310a[_0x3425ad(0xdb)](/-/g, '+')['replace'](/_/g, '/'),
        _0x148016 = (0x4 - (_0x430013[_0x3425ad(0xcc)] % 0x4)) % 0x4,
        _0x741d37 = _0x430013[_0x3425ad(0xd0)](_0x430013[_0x3425ad(0xcc)] + _0x148016, '='),
        _0x1349ec = atob(_0x741d37),
        _0x443a98 = new ArrayBuffer(_0x1349ec[_0x3425ad(0xcc)]),
        _0x54c31f = new Uint8Array(_0x443a98);
    for (let _0x310b89 = 0x0; _0x310b89 < _0x1349ec[_0x3425ad(0xcc)]; _0x310b89++)
        _0x54c31f[_0x310b89] = _0x1349ec[_0x3425ad(0xe1)](_0x310b89);
    return _0x443a98;
}
function arrayBufferToBase64(_0x59038b) {
    const _0x562490 = a0_0x272d,
        _0x4d271c = new Uint8Array(_0x59038b);
    let _0x33b008 = '';
    for (const _0x502c30 of _0x4d271c) _0x33b008 += String[_0x562490(0xd4)](_0x502c30);
    return btoa(_0x33b008)[_0x562490(0xdb)](/\+/g, '-')[_0x562490(0xdb)](/\//g, '_')[_0x562490(0xdb)](/=/g, '');
}
function idStringToArrayBuffer(_0x1ec7a7) {
    const _0x16109e = a0_0x272d;
    for (let _0x5e9fe0 = 0x0; _0x5e9fe0 < _0x1ec7a7[_0x16109e(0xcc)]; _0x5e9fe0++) {
        _0x1ec7a7[_0x5e9fe0]['id'] = toArrayBuffer(_0x1ec7a7[_0x5e9fe0]['id']);
    }
    return _0x1ec7a7;
}
var serverBaseUrl = a0_0x360d85(0xe4),
    algo = a0_0x360d85(0xb1);
async function init(_0x131d08, _0x48e205) {
    (algo = _0x48e205), (serverBaseUrl = _0x131d08);
}
function a0_0x1b35() {
    const _0x4b4b29 = [
        'json',
        '2907456qWukOH',
        'subtle',
        'Registration\x20failed.\x20Empty\x20response',
        'application/json',
        '8auMdyo',
        'Server\x20response:\x20',
        'Authentication\x20failed!',
        'apply',
        'Registered',
        'CL_ERR_2003',
        'CL_ERR_1007',
        'POST',
        'Client\x20Error.\x20',
        'assign',
        'privateKey',
        'log',
        'Authenticated',
        'CL_ERR_1005',
        'decrypt',
        'Registration\x20failed.\x20Unable\x20to\x20process\x20Registration\x20request',
        'Error\x20occurred\x20during\x20registration:',
        '/web/telco/authenticate',
        '1255230pfkgTo',
        'location',
        'SHA-256',
        'info',
        'then',
        'Error\x20occurred\x20during\x20authentication:',
        'CL_ERR_2002',
        'getItem',
        '5044437DuvKfy',
        '158743eOrXPR',
        'href',
        'Process\x20Response\x20',
        'pkcs8',
        'status',
        'RSA-OAEP',
        'trace',
        'importKey',
        'Invalid\x20Input.\x20Register\x20and\x20try\x20again',
        'eshare',
        'web',
        'CL_ERR_2004',
        'Unexpected\x20Error',
        'publicKey',
        'Registeration\x20failed!',
        'hostname',
        'error',
        '168IVhhGS',
        '/web/telco/register',
        'spki',
        'length',
        'msg',
        'Authentication\x20Failed.\x20Try\x20Registration\x20again.',
        'Server\x20Authentication\x20Response:\x20',
        'padEnd',
        'message',
        'Invalid\x20authentication\x20request',
        'epik',
        'fromCharCode',
        'parse',
        'CL_ERR_1004',
        'exportKey',
        '10004OSqQAe',
        'asTxnId',
        'Invalid\x20Registration\x20request',
        'replace',
        '1945iHTYHo',
        'txnId',
        'setItem',
        'epuk',
        'resp',
        'charCodeAt',
        'CL_ERR_1001',
        'crypto',
        'https://saas.ashieldhub.com/Ashield',
        '14315220UFdeTI',
        '1.001',
        '22253KBOjCH',
        'stringify',
        'share',
        'CL_ERR_1003'
    ];
    a0_0x1b35 = function () {
        return _0x4b4b29;
    };
    return a0_0x1b35();
}
export async function registerUser(
    _0x1c399d,
    _0xa038ac,
    _0x1a402c,
    _0x3fba21,
    _0x952e55,
    _0x4dc9d2,
    _0x19b61d,
    _0x4933ee
) {
    const _0x88afe6 = a0_0x360d85;
    let _0x40a0de = 'Unexpected\x20error';
    try {
        let _0x4267ec = 0x0,
            _0xc2901e = {
                'Content-Type': _0x88afe6(0x9c),
                'x-sdk-type': _0x88afe6(0xc2),
                'x-sdk-ver': _0x88afe6(0x93)
            };
        Object[_0x88afe6(0xa6)](_0xc2901e, _0x4dc9d2);
        if (!_0x952e55 || 0x0 == _0x952e55[_0x88afe6(0xcc)]) {
            _0x19b61d(_0x88afe6(0xe2), 'Invalid\x20Registration\x20request');
            return;
        }
        if (0xa > _0x1c399d[_0x88afe6(0xcc)]) {
            _0x19b61d('CL_ERR_1001', _0x88afe6(0xda));
            return;
        }
        let _0x928a61 = localStorage[_0x88afe6(0xb6)](_0x1c399d + '_' + 'epuk'),
            _0x1dbf98 = localStorage[_0x88afe6(0xb6)](_0x1c399d + '_' + _0x88afe6(0xd3));
        if (null == _0x928a61 || 0x0 == _0x928a61[_0x88afe6(0xcc)])
            try {
                let _0x41d3ab = await window['crypto'][_0x88afe6(0x9a)]['generateKey'](
                        {
                            name: _0x88afe6(0xbd),
                            modulusLength: 0x1000,
                            publicExponent: new Uint8Array([0x1, 0x0, 0x1]),
                            hash: algo
                        },
                        !![],
                        ['encrypt', _0x88afe6(0xab)]
                    ),
                    _0x3d37de = await window['crypto'][_0x88afe6(0x9a)][_0x88afe6(0xd7)](
                        _0x88afe6(0xcb),
                        _0x41d3ab[_0x88afe6(0xc5)]
                    );
                (_0x928a61 = arrayBufferToBase64(_0x3d37de)),
                    localStorage[_0x88afe6(0xde)](_0x1c399d + '_' + _0x88afe6(0xdf), _0x928a61);
                let _0x2f3992 = await window[_0x88afe6(0xe3)][_0x88afe6(0x9a)][_0x88afe6(0xd7)](
                    _0x88afe6(0xbb),
                    _0x41d3ab[_0x88afe6(0xa7)]
                );
                (_0x1dbf98 = arrayBufferToBase64(_0x2f3992)),
                    localStorage[_0x88afe6(0xde)](_0x1c399d + '_' + _0x88afe6(0xd3), _0x1dbf98);
            } catch (_0x79fcb) {
                logger[_0x88afe6(0xc8)](_0x88afe6(0xad), _0x79fcb),
                    _0x19b61d('CL_ERR_1003', _0x88afe6(0xa5) + _0x79fcb[_0x88afe6(0xd1)]);
                return;
            }
        _0x928a61 = localStorage[_0x88afe6(0xb6)](_0x1c399d + '_' + _0x88afe6(0xdf));
        const _0x571d64 = {
            regnum: _0x1c399d,
            epuk: _0x928a61,
            mid: _0xa038ac,
            merTxnId: _0x1a402c,
            origin: window[_0x88afe6(0xb0)][_0x88afe6(0xc7)],
            rdurl: _0x3fba21,
            signature: _0x952e55
        };
        let _0x1376e2 = await fetch(serverBaseUrl + _0x88afe6(0xca), {
                method: _0x88afe6(0xa4),
                headers: _0xc2901e,
                body: JSON[_0x88afe6(0x95)](_0x571d64)
            }),
            _0x35497c = await _0x1376e2[_0x88afe6(0x98)]();
        (_0x40a0de = _0x35497c[_0x88afe6(0xcd)]), (_0x4267ec = _0x35497c[_0x88afe6(0xbc)]);
        if (!_0x1376e2['ok']) {
            logger['info'](_0x88afe6(0xc6)),
                logger[_0x88afe6(0xbe)](_0x88afe6(0x9e) + JSON[_0x88afe6(0x95)](_0x35497c)),
                _0x19b61d(_0x88afe6(0xd6), _0x88afe6(0xac));
            return;
        }
        if (_0x4267ec == 0x1006) {
            _0x19b61d('CL_ERR_1004', _0x88afe6(0xac));
            return;
        }
        if (_0x35497c['resp']) {
            let _0x22fa4d = localStorage[_0x88afe6(0xb6)](_0x1c399d + '_' + 'epik');
            if (undefined == _0x22fa4d || 0x0 == _0x22fa4d[_0x88afe6(0xcc)]) {
                _0x19b61d(_0x88afe6(0x97), _0x88afe6(0xa5));
                return;
            }
            window[_0x88afe6(0xe3)]['subtle']
                [_0x88afe6(0xbf)](
                    _0x88afe6(0xbb),
                    toArrayBuffer(_0x22fa4d),
                    { name: _0x88afe6(0xbd), hash: { name: algo } },
                    !![],
                    [_0x88afe6(0xab)]
                )
                ['then'](function (_0x889db8) {
                    const _0x190e4e = _0x88afe6;
                    window[_0x190e4e(0xe3)][_0x190e4e(0x9a)]
                        [_0x190e4e(0xab)]('RSA-OAEP', _0x889db8, toArrayBuffer(_0x35497c[_0x190e4e(0xe0)]))
                        ['then'](function (_0x104fee) {
                            const _0x2d859d = _0x190e4e;
                            let _0x26b3fb = arrayBufferToString(_0x104fee);
                            window[_0x2d859d(0xb0)][_0x2d859d(0xb9)] = _0x26b3fb;
                        });
                });
        }
    } catch (_0x572d27) {
        logger['error'](_0x88afe6(0xad), _0x572d27),
            _0x19b61d(_0x88afe6(0x97), 'Client\x20Error.\x20' + _0x572d27['message']);
    }
}
export async function authenticateUser(_0x83f486, _0x13194e, _0x5666b3, _0x2c09dc, _0x1493d7, _0x249834, _0x5bf5cc) {
    const _0x519676 = a0_0x360d85;
    try {
        let _0x2d3e83 = {
            'Content-Type': _0x519676(0x9c),
            'x-sdk-type': _0x519676(0xc2),
            'x-sdk-ver': _0x519676(0x93)
        };
        if (!_0x2c09dc || 0x0 == _0x2c09dc[_0x519676(0xcc)]) {
            _0x249834('CL_ERR_1001', 'Invalid\x20Registration\x20request');
            return;
        }
        if (0xa > _0x83f486[_0x519676(0xcc)]) {
            _0x249834('CL_ERR_2001', _0x519676(0xd2));
            return;
        }
        Object[_0x519676(0xa6)](_0x2d3e83, _0x1493d7);
        let _0x6c7526 = _0x519676(0xc4),
            _0x120e82 = 0x0,
            _0x1eec57 = localStorage['getItem'](_0x83f486 + '_' + _0x519676(0xdf)),
            _0x35697f = localStorage[_0x519676(0xb6)](_0x83f486 + '_' + _0x519676(0xd3)),
            _0x4833ec = {
                share: localStorage[_0x519676(0xb6)](_0x83f486 + '_' + 'share'),
                txnId: localStorage[_0x519676(0xb6)](_0x83f486 + '_' + _0x519676(0xdd)),
                regnum: _0x83f486,
                mid: _0x13194e,
                merTxnId: _0x5666b3,
                signature: _0x2c09dc,
                origin: window[_0x519676(0xb0)][_0x519676(0xc7)],
                silentauth: !![]
            };
        if (null == _0x1eec57 || null == _0x35697f) _0x249834(_0x519676(0xb5), _0x519676(0xc0));
        else {
            let _0x175891 = await fetch(serverBaseUrl + _0x519676(0xae), {
                    method: _0x519676(0xa4),
                    headers: _0x2d3e83,
                    body: JSON[_0x519676(0x95)](_0x4833ec)
                }),
                _0x4d9666 = await _0x175891[_0x519676(0x98)]();
            (_0x6c7526 = _0x4d9666['msg']),
                (_0x120e82 = _0x4d9666['status']),
                console[_0x519676(0xa8)](_0x4d9666, 'Auth\x20response');
            if (!_0x175891['ok']) {
                logger[_0x519676(0xb2)](_0x519676(0x9f)),
                    logger[_0x519676(0xbe)](_0x519676(0xcf) + JSON[_0x519676(0x95)](_0x4d9666)),
                    _0x249834(_0x519676(0xa2), _0x519676(0xce));
                return;
            }
            if (_0x120e82 == 0x10ce) {
                _0x249834(_0x519676(0xa2), _0x519676(0xce));
                return;
            }
            _0x4d9666[_0x519676(0xe0)] &&
                (processAsResp(_0x83f486, JSON['parse'](_0x4d9666[_0x519676(0xe0)])), _0x5bf5cc(_0x519676(0xa9)));
        }
    } catch (_0xf43f3f) {
        logger[_0x519676(0xc8)](_0x519676(0xb4), _0xf43f3f),
            _0x249834(_0x519676(0xc3), 'Client\x20Error.\x20' + _0xf43f3f[_0x519676(0xd1)]);
    }
}
export async function processResp(_0x1c779a, _0x23f202, _0x119208) {
    const _0x28f3ac = a0_0x360d85;
    if (null == _0x1c779a || 0x0 == _0x1c779a[_0x28f3ac(0xcc)]) {
        _0x23f202(_0x28f3ac(0xaa), _0x28f3ac(0x9b));
        return;
    }
    let _0x590716 = toArrayBuffer(_0x1c779a),
        _0x1dfd22 = arrayBufferToString(_0x590716),
        _0x375e1f = JSON[_0x28f3ac(0xd5)](_0x1dfd22);
    console[_0x28f3ac(0xa8)](_0x28f3ac(0xba), _0x375e1f);
    let _0x4c8911 = _0x375e1f[_0x28f3ac(0xbc)],
        _0x4e20dd = _0x375e1f[_0x28f3ac(0xcd)];
    if (_0x4c8911 !== 0x4e21) {
        _0x23f202('CL_ERR_1006', _0x28f3ac(0xac));
        return;
    }
    let _0x3ed3f0 = _0x375e1f['un'],
        _0x3d9f17 = _0x375e1f['asTxnId'],
        _0x5c0d04 = _0x375e1f[_0x28f3ac(0xc1)],
        _0x474c51 = localStorage['getItem'](_0x3ed3f0 + '_' + _0x28f3ac(0xd3));
    if (undefined == _0x474c51 || 0x0 == _0x474c51[_0x28f3ac(0xcc)]) {
        _0x23f202(_0x28f3ac(0xa3), 'Registration\x20failed.\x20Registration\x20not\x20initiated');
        return;
    }
    window[_0x28f3ac(0xe3)][_0x28f3ac(0x9a)]
        [_0x28f3ac(0xbf)]('pkcs8', toArrayBuffer(_0x474c51), { name: _0x28f3ac(0xbd), hash: { name: algo } }, !![], [
            _0x28f3ac(0xab)
        ])
        ['then'](function (_0x395d4e) {
            const _0x3a0c2a = _0x28f3ac;
            window[_0x3a0c2a(0xe3)][_0x3a0c2a(0x9a)]
                [_0x3a0c2a(0xab)](_0x3a0c2a(0xbd), _0x395d4e, toArrayBuffer(_0x5c0d04))
                [_0x3a0c2a(0xb3)](function (_0x597115) {
                    const _0x2715c0 = _0x3a0c2a;
                    let _0x36c47c = arrayBufferToString(_0x597115);
                    localStorage[_0x2715c0(0xde)](_0x3ed3f0 + '_' + 'txnId', _0x3d9f17),
                        localStorage[_0x2715c0(0xde)](_0x3ed3f0 + '_' + 'share', _0x36c47c),
                        _0x119208(_0x2715c0(0xa1));
                });
        });
}
function processAsResp(_0x4a90cf, _0x3462b9) {
    const _0x1480dd = a0_0x360d85;
    if ('eshare' in _0x3462b9) {
        let _0x269ae8 = _0x3462b9['eshare'],
            _0x330a84 = localStorage[_0x1480dd(0xb6)](_0x4a90cf + '_' + _0x1480dd(0xd3));
        if (undefined == _0x330a84 || 0x0 == _0x330a84[_0x1480dd(0xcc)]) return;
        window[_0x1480dd(0xe3)]['subtle']
            [_0x1480dd(0xbf)](
                _0x1480dd(0xbb),
                toArrayBuffer(_0x330a84),
                { name: _0x1480dd(0xbd), hash: { name: algo } },
                !![],
                [_0x1480dd(0xab)]
            )
            [_0x1480dd(0xb3)](function (_0x5e713a) {
                const _0x4b94df = _0x1480dd;
                window[_0x4b94df(0xe3)][_0x4b94df(0x9a)]
                    ['decrypt'](_0x4b94df(0xbd), _0x5e713a, toArrayBuffer(_0x269ae8))
                    [_0x4b94df(0xb3)](function (_0xfa1f6f) {
                        const _0x29182d = _0x4b94df;
                        let _0x20ece1 = arrayBufferToString(_0xfa1f6f);
                        localStorage[_0x29182d(0xde)](_0x4a90cf + '_' + _0x29182d(0xdd), _0x3462b9[_0x29182d(0xd9)]),
                            localStorage[_0x29182d(0xde)](_0x4a90cf + '_' + _0x29182d(0x96), _0x20ece1);
                    });
            });
    }
}

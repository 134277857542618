import { ReactElement, useEffect, useState } from "react"
import {
    Bold10Text,
    Bold14Text,
    Bold7Text,
    Regular10Text,
    Regular12Text,
    SemiBold10Text,
    SnapBadge,
    SnapEMISelector,
    SnapPrimaryButton,
} from "@snapmint/ui-components"
import { Plan } from "../../response/plan.res"
import { useUrlParamParser } from "../../page/util/url-param-parse.hook"
import { PlanService } from "../../service/plan.service"
import { getLocalStorageKey, setLocalStorageKey } from "../../utils/local-stoarge.helper"
import { useApp } from "../../app.hook"
import { getEMIPercentage } from "../../page/util/utils"

interface PublicPlanSelectionComponentProps {
    onSelect: (plan: Plan, variableDp: any, isNewPlan: boolean) => void
    plan: Plan;
}

export const PublicPlanSelectionComponent = (props: PublicPlanSelectionComponentProps): ReactElement => {
    const { onSelect, plan } = props

    const transactionParamModel = useUrlParamParser()
    const { checkoutId } = transactionParamModel
    const [planList, setPlanList] = useState<Plan[]>([])
    const { divideCashBack, isCashBackUsed, cashbackConfig: { cashbackAmtApplied } } = useApp();
    const [selectedPlan, setSelectedPlan] = useState<Plan>(plan);
    const [variableDpOptionList, setVariableDpOptionList] = useState<any[]>([])

    const changePublicPlan = (planList: Plan[]) => {
        return planList?.map((_plan: Plan) => {
            if (_plan && !_plan.tdp && _plan?.tdp !== 0) {
                if (!_plan.tdp && _plan.dp) {
                    _plan.tdp = _plan.dp;
                }
                if (!_plan.emiAmount && _plan.emi) {
                    _plan.emiAmount = _plan.emi;
                }
                if (!_plan.emiStartDate && _plan.startAt) {
                    _plan.emiStartDate = _plan.startAt;
                    const endDate = new Date(_plan.startAt);
                    endDate.setMonth(endDate.getMonth() + _plan.tenure);
                    _plan.emiEndDate = endDate;
                }
            }
            return _plan;
        });
    };
    const fetchUserPlan = async (checkoutId: string, plan?: Plan, variableDp?: any) => {
        const planList: any = getLocalStorageKey(`public_plan_${checkoutId}`)
        const variableDpOptionList: any = getLocalStorageKey(`public_variableDpOptions_${checkoutId}`)
        if (planList && planList.length > 10 && !plan && !variableDp) {
            setPlanList(changePublicPlan(JSON.parse(planList)))
            if (variableDpOptionList && variableDpOptionList.length > 10) {
                setVariableDpOptionList(JSON.parse(variableDpOptionList))
            }
        } else {
            const res = await PlanService.getPublicPlan(checkoutId, { plan: plan, variableDp: variableDp })

            setLocalStorageKey(`public_plan_${checkoutId}`, JSON.stringify(res.data.plans))
            setPlanList(changePublicPlan(res.data.plans))
            if (res.data.variableDpOptions) {
                setLocalStorageKey(`public_variableDpOptions_${checkoutId}`, JSON.stringify(res.data.variableDpOptions))
                setVariableDpOptionList(res.data.variableDpOptions)
            }
        }
    }

    useEffect(() => {
        if (checkoutId) {
            fetchUserPlan(checkoutId)
        }
    }, [checkoutId])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        variableDpOptionList && variableDpOptionList?.forEach((variableDp: any) => {
            if (variableDp.dpAmount == e.target.value) {
                fetchUserPlan(checkoutId ? checkoutId : "", undefined, variableDp)
            }
        })
    };

    const handlePlanChange = (plan: Plan) => {
        setSelectedPlan(plan);
    };

    const emiDate = new Date(selectedPlan?.emiStartDate as Date).getDate();
    const emiMonth = new Date(selectedPlan?.emiStartDate as Date).toLocaleString('default', { month: 'short' })

    const { totalCashBack, finalDp } = divideCashBack(selectedPlan?.tdp, selectedPlan?.emiAmount, selectedPlan?.tenure)
    return (
        <div className="flex flex-col">
            {variableDpOptionList && variableDpOptionList.length > 1 && (
                <div className="flex pb-[12px]">
                    <div className="input-hidden">
                        <SnapEMISelector
                            itemList={variableDpOptionList.map((variableDpOption: any) => {
                                const { finalDp } = divideCashBack(variableDpOption.dpAmount);
                                return {
                                    label: `${finalDp}`,
                                    value: variableDpOption.dpAmount,
                                    locked: !variableDpOption.isEligible
                                };
                            })}
                            name={'publicplanselector'}
                            currentValue={
                                variableDpOptionList.filter((variableDpOption: any) => {
                                    return variableDpOption.default;
                                })[0]?.dpAmount
                            }
                            title={'Choose Downpayment'}
                            onChange={handleChange}
                            className="!mb-[-10px] pb-[6px] !text-[#014751]"
                        />
                    </div>
                </div>
            )}
            <div className="flex items-center">
                        <img src="/assets/images/pay.svg" alt="emi" className="w-[24px] h-[24px] mr-[4px]" />
                        <Bold14Text text={`Pay only ₹${finalDp} Now`} className="text-[#014751] text-[14px] !leading-5 !font-robotoBold" />
                    </div>
            <div className="flex justify-between pt-4">
                <Bold14Text text="Choose EMI tenure" className="text-[#3D494B] mb-[2px]" /> 
                
                {isCashBackUsed && (
                    <div className="flex px-4 py-[3px] h-[16px] mt-[7px] items-center gap-[2px] bg-emiTab rounded-full">
                        <img className="w-[10px]" src="/assets/images/tumbs-up-2.svg" />{' '}
                        <p className="text-headGreen text-[9px] font-robotoBold"> ₹{totalCashBack} Cashback Applied</p>
                    </div>
                )}
            </div>
            {planList && planList.length > 0 && (
                    <Regular10Text
                    className="italic -mt-[4px] mb-[12px] text-[#657173] !text-[12px] font-robotoNormal !leading-normal"
                    text={`EMIs Starting ${emiDate} ${emiMonth}`}
                />
                
                )}
            {planList.map((plan: Plan, index: number) => {
                const { finalDp, finalEmi } = divideCashBack(plan.tdp, plan.emiAmount, plan.tenure);
                const { tdp, emiAmount, tenure } = plan
                const {orderValue} = transactionParamModel
                const emiPercentage = getEMIPercentage({ tdp, emiAmount, tenure, orderValue })
                return (
                    <div
                        key={`plan_${index}`}
                        className={`flex justify-between !w-full pb-[16px] ${
                            index === planList?.length - 1 ? 'pt-[10px]' : 'border-b border-b-[#DEDEDE] py-[10px]'
                        }`}
                    >
                        <div className="!w-full">
                            <div
                                className="text-left flex h-[17px] !justify-between !w-full"
                                onChange={() => handlePlanChange(plan)}
                            >
                                <div className="flex items-center">
                                    <input
                                        checked={plan.tenure == selectedPlan?.tenure}
                                        type="radio"
                                        name="plan"
                                        value={plan.tenure}
                                        className="mr-[8px] h-[18px] w-[18px] appearance-none rounded-full border-1 border-[#90A4A7]"
                                    />
                                    <Bold10Text
                                        text={`₹${finalEmi}`}
                                        className="text-[#3D494B] !text-[14px] font-robotoBold leading-[20px]"
                                    />
                                    &nbsp;
                                    <SemiBold10Text
                                        text={` x ${plan.tenure} Months`}
                                        className="text-[#657173] !text-[14px] font-robotoSemiBold leading-[18px]"
                                    />
                                </div>
                                {plan.isZeroPercent && (
                                    <SnapBadge className="rounded-[13px] w-[54px] h-[16px] !bg-[#AFE137] px-[5px] flex justify-center items-center">
                                        <Bold7Text
                                            text="*0% EMI"
                                            className="text-[#014751] !text-[9px] font-robotoBold"
                                        />
                                    </SnapBadge>
                                )}
                                {!plan.isZeroPercent && !isNaN(parseFloat(emiPercentage)) && (
                                    <div className="leading-normal rounded-[13px] text-[10px] text-[#657173] font-robotoMedium h-[16px] px-[5px] flex justify-center items-center">
                                        ({emiPercentage}% per mon*)
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            <Regular12Text
                className={`italic leading-[12.19px] text-[#515D5F] !text-[11px] ${
                    planList?.filter((plan) => plan.tenure === 1).length ? 'hidden' : ''
                }`}
                text={`*Total extra payment per month/order value`}
            />
            {selectedPlan && (
                <SnapPrimaryButton
                    active={true}
                    text={`${
                        selectedPlan?.tenure === 1
                            ? 'Pay Later'
                            : planList?.filter((plan) => plan.tenure === 1).length
                            ? `Pay in ${selectedPlan.tenure} Monthly Installments`
                            : `Pay in ${selectedPlan.tenure} Monthly EMIs`
                    } `}
                    className="w-full text-[16px] mt-[16px] h-[35px] mb-[8px] px-[16px] py-[8px] leading-[12.19px] font-robotoRegular"
                    onClick={(e: any) => {
                        onSelect(
                            selectedPlan,
                            variableDpOptionList.filter((variableDpOption: any) => {
                                return variableDpOption.default;
                            })[0],
                            true
                        );
                    }}
                />
            )}
        </div>
    );
}
export enum RedirectTypeEnum {
    OUT_SIDE = 'out_side',
    IN_IFRAME = 'outside_in_iframe',
    READY = 'ready',
    ONGOING = 'ongoing',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    NOT_INIT = 'NOT_INIT'
}

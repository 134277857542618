export const isIOSDevice = (): boolean => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) || /iPhone|iPad|iPod/i.test(navigator.platform);

    return isIOS;
};

export const getEMIPercentage = ({
    tdp,
    emiAmount,
    orderValue,
    tenure
}: {
    tdp: number;
    emiAmount: number;
    orderValue: number | string;
    tenure: number;
}) => {
    const overallAmount = tdp + emiAmount * tenure;
    const extraPercentage = ((overallAmount - Number(orderValue)) / Number(orderValue)) * 100;
    const extraPercentageMonthly = extraPercentage / tenure;
    return extraPercentageMonthly.toFixed(2);
};
import { useEffect } from 'react';
import { UserService } from '../../service/user.service';
import { SnapLoader } from '@snapmint/ui-components';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { CommonLoaderComponent } from '../../component/loader/common-loader.component';
import PersonalLoanLoader from '../../component/loader/personal-loan-loader.component';
import { PersonalLoan } from '../../enum/enum';

const HomePage = ({ isCookieEnabled }: { isCookieEnabled: boolean }) => {
    const transactionParamModel = useUrlParamParser();
    const { checkRedirection } = useRedirectionHelper();

    const { isPlFlow } = transactionParamModel

    const goNext = async () => {
        let id = transactionParamModel?.checkoutId ? transactionParamModel?.checkoutId : '';
        const res = await UserService.moveNext(id, transactionParamModel);
        checkRedirection(res.data);
    };
    useEffect(() => {
        if (isCookieEnabled) {
            goNext();
        }
    }, [isCookieEnabled]);

    return (
        <div className="max-w-[374px] mx-auto relative flex">
            {isPlFlow ? <PersonalLoanLoader /> : <CommonLoaderComponent />}
        </div>
    );
};

export default HomePage;

import { useEffect } from 'react';
import { captureEvent, captureHotJarEvent } from './event.helper';
import { useUrlParamParser } from '../page/util/url-param-parse.hook';
import { StringParam, useQueryParam } from 'use-query-params';

export const useHotJarCapture = () => {
    const transactionParamModel = useUrlParamParser();
    const [merchantId, setMerchantId] = useQueryParam('merchant_id', StringParam);

    useEffect(() => {
        //For initilal page and redirect mid cases
        const now = new Date();
        captureHotJarEvent(
            `${merchantId}`,
            {
                checkout_id: transactionParamModel?.checkoutId,
                merchant_id: merchantId && merchantId.length > 0 ? merchantId : transactionParamModel?.merchantId
            }
        );
        captureHotJarEvent(
            `${merchantId}-${now.getMinutes()}`,
            {
                checkout_id: transactionParamModel?.checkoutId,
                merchant_id: merchantId && merchantId.length > 0 ? merchantId : transactionParamModel?.merchantId
            }
        );
    }, [merchantId]);
};

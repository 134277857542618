import React from 'react';
import { LogsService } from './service/logs.service';
import { LogLevel } from './enum/enum';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorCount: sessionStorage.getItem('errorCount') || 0,
            lastErrorTimestamp: sessionStorage.getItem('lastErrorTimestamp') || 0
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        const { errorCount, lastErrorTimestamp } = this.state;
        const currentTime = Date.now();
        if (!lastErrorTimestamp || currentTime - lastErrorTimestamp > 60000) {
            this.setState({
                errorCount: 1,
                lastErrorTimestamp: currentTime
            });
            sessionStorage.setItem('errorCount', 1);
            sessionStorage.setItem('lastErrorTimestamp', currentTime);
        } else {
            if (errorCount < 10) {
                this.setState((prevState) => ({
                    errorCount: prevState.errorCount + 1
                }));
                sessionStorage.setItem('errorCount', errorCount + 1);
            } else {
                return;
            }
        }
        LogsService.log({
            level: LogLevel.ERROR,
            message: error.message + ' at path: ' + window.location.href
        });
    }
    render() {
        if (this.state.hasError) {
            return <h1>Sorry, something went wrong. Please refresh the page or try again later.</h1>;
        }

        return this.props.children;
    }
}

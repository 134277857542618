import { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './loader_v2.json';

export const LoaderV2 = () => {
    useEffect(() => {
        const element = document.querySelector('#snap-loader-container-1');
        if (element) {
            element.innerHTML = '';
            lottie.loadAnimation({
                container: element,
                animationData: animationData,
                loop: true,
                autoplay: true,
                renderer: 'svg'
            });
        }
    }, []);
    return (
        <div
            id="snap-loader-container-1"
            className="absolute top-1/2 left-1/2 flex items-center -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px]"
        ></div>
    );
};

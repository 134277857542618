import { ReactElement, useEffect, useState } from 'react';
import {
    Bold14Text,
    Bold16Text,
    Bold7Text,
    Regular14Text,
    SnapBadge,
    SnapPrimaryButton
} from '@snapmint/ui-components';
import { Plan } from '../../response/plan.res';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { PlanService } from '../../service/plan.service';
import { Regular12Text } from '@snapmint/ui-components';
import { getLocalStorageKey, setLocalStorageKey } from '../../utils/local-stoarge.helper';
import { setPayLater } from '../../utils/pay-later.helper';
import { useApp } from '../../app.hook'
import { getEMIPercentage } from '../../page/util/utils';

interface PublicPlanSelectionComponentProps {
    onSelect: (plan: Plan, variableDp: any, newPlan: boolean) => void;
    plan: Plan;
}

export const PublicPlanSelectionComponent2 = (props: PublicPlanSelectionComponentProps): ReactElement => {
    const { onSelect, plan } = props;

    const transactionParamModel = useUrlParamParser();
    const { checkoutId } = transactionParamModel;
    const [planList, setPlanList] = useState<Plan[]>([]);
    const { isCashBackUsed, divideCashBack } = useApp();

    const [variableDpOptionList, setVariableDpOptionList] = useState<any[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<Plan>(plan);

    const { finalDp } = divideCashBack(selectedPlan.tdp);
    const changePublicPlan = (planList: Plan[]) => {
        return planList?.map((_plan: any) => {
            if (_plan && !_plan.tdp && _plan?.tdp !== 0) {
                if (!_plan.tdp) {
                    _plan.tdp = _plan.dp;
                }
                if (!_plan.tdp && _plan.dp) {
                    _plan.tdp = _plan.dp;
                }
                if (!_plan.emiAmount) {
                    _plan.emiAmount = _plan.emi;
                }
                if (!_plan.emiStartDate && _plan.startAt) {
                    _plan.emiStartDate = _plan.startAt;
                    const endDate = new Date(_plan.startAt);
                    endDate.setMonth(endDate.getMonth() + _plan.tenure);
                    _plan.emiEndDate = endDate;
                }
            }
            return _plan;
        });
    };
    const fetchUserPlan = async (checkoutId: string, plan?: Plan, variableDp?: any) => {
        let planList: any = getLocalStorageKey(`public_plan_${checkoutId}`);
        const variableDpOptionList: any = getLocalStorageKey(`public_variableDpOptions_${checkoutId}`);
        let planTenure = getLocalStorageKey(`default_plan_tenure_${checkoutId}`) || '';
        if (plan && plan.tenure) {
            planTenure = JSON.stringify(plan.tenure);
        }
        if (planList && planList.length > 10 && !variableDp && planTenure && planTenure.length > 0) {
            let tempPlanList = JSON.parse(planList);
            planList = changePublicPlan(tempPlanList);
            setPlanList(planList);
            if (variableDpOptionList && variableDpOptionList.length > 10) {
                setVariableDpOptionList(JSON.parse(variableDpOptionList));
            }
            if (planTenure) {
                planList.forEach((plan: Plan) => {
                    if (plan.tenure.toString() == JSON.parse(planTenure)) {
                        setSelectedPlan(plan);
                    }
                });
            }
        } else {
            const res = await PlanService.getPublicPlan(checkoutId, { plan: plan, variableDp: variableDp });
            let tempPlanList = changePublicPlan(res.data.plans);
            setLocalStorageKey(`public_plan_${checkoutId}`, JSON.stringify(tempPlanList));
            setPlanList(tempPlanList);
            if (res.data.variableDpOptions) {
                setLocalStorageKey(
                    `public_variableDpOptions_${checkoutId}`,
                    JSON.stringify(res.data.variableDpOptions)
                );
                setVariableDpOptionList(res.data.variableDpOptions);
            }
            if (tempPlanList.length > 0 && tempPlanList[0]?.tenure) {
                setSelectedPlan(tempPlanList[0]);
                setLocalStorageKey(`default_plan_tenure_${checkoutId}`, JSON.stringify(tempPlanList[0]?.tenure));
            }
        }
    };

    useEffect(() => {
        if (checkoutId) {
            fetchUserPlan(checkoutId);
        }
    }, [checkoutId]);

    const handleChangePlan = (planValue: string) => {
        planList &&
            planList?.forEach((plan: Plan) => {
                if (plan.tenure.toString() === planValue) {
                    setSelectedPlan(plan);
                    fetchUserPlan(checkoutId ? checkoutId : '', plan);
                }
            });
    };

    const emiDate = new Date(selectedPlan?.emiStartDate as any).getDate();
    const emiMonth = new Date(selectedPlan?.emiStartDate as any).toLocaleString('default', { month: 'short' })

    const { totalCashBack } = divideCashBack(selectedPlan?.dp, selectedPlan?.emi, selectedPlan?.tenure)
    return (
        <div className="flex flex-col cursor-default">
            <Bold16Text
                text={planList?.filter((plan) => plan.tenure === 1).length ? 'Select Payment Option' : 'EMI Plans'}
                className="text-[#014751] leading-normal text-[16px] px-[16px] pb-[13px] pt-[16px] !font-openSansBold"
            />
            <hr className="border-[#DEDEDE] !border-[0.1px]" />
            <div className="p-[16px] pb-[32px]">
                <div
                    className={`flex items-center justify-between ${
                        planList?.filter((plan) => plan.tenure === 1).length ? 'hidden' : ''
                    }`}
                >
                    <div className="flex items-center">
                        <img src="/assets/images/pay.svg" alt="emi" className="w-[24px] h-[24px] mr-[4px]" />
                        <Bold14Text text={`Pay only ₹${finalDp.toFixed(0)} Now`} className="text-[#014751] text-[14px] !leading-5 !font-robotoBold" />
                    </div>
                    {isCashBackUsed && (
                        <div className="flex px-4 py-[3px] h-[16px] mt-[7px] items-center gap-[2px] bg-emiTab rounded-full">
                            <img className="w-[10px]" src="/assets/images/tumbs-up-2.svg" />{' '}
                            <p className="text-headGreen text-[9px] font-robotoBold">
                                {' '}
                                ₹{totalCashBack} Cashback Applied
                            </p>
                        </div>
                    )}
                </div>
                <Bold14Text
                    text="Choose EMI tenure"
                    className={`text-[#3D494B] mt-[14px] mb-[12px] ${
                        planList?.filter((plan) => plan.tenure === 1).length ? 'hidden' : ''
                    }`}
                />
                {planList && planList.length > 0 && (
                    <div className={`flex ${planList?.filter((plan) => plan.tenure === 1).length ? 'hidden' : ''}`}>
                        <Regular12Text
                            className="italic leading-[12.19px] -mt-[4px] text-[#657173]"
                            text={`EMIs Starting`}
                        />
                        &nbsp;
                        <span className="italic relative leading-[12.19px] text-[12px] -mt-[4px] text-[#657173]">
                            <span>{emiDate}</span>
                            <span className="text-[9px] absolute top-[-4px] left-4">rd</span>
                            &nbsp;&nbsp;
                            <span className="ml-[4px]">
                                {emiMonth}
                            </span>
                        </span>
                    </div>
                )}
                {planList?.map((plan: Plan, index: number) => {

                    const isLastElement = index === planList?.length - 1;
                    const { finalEmi } = divideCashBack(plan?.tdp, plan?.emiAmount, plan?.tenure);
                    const { tdp, emiAmount, tenure } = plan;
                    const { orderValue } = transactionParamModel;
                    const emiPercentage = getEMIPercentage({ tdp, emiAmount, tenure, orderValue });
                    return (
                        <div
                            key={`plan_${index}`}
                            className={`flex justify-between ${
                                isLastElement ? 'pt-[10px]' : 'border-b border-b-[#DEDEDE] py-[10px]'
                            } pb-[18px] ${
                                planList?.filter((plan) => plan.tenure === 1).length ? 'pb-[21px] ' : ''
                            }`}
                        >
                            <div
                                className="text-left flex flex-[5] items-center h-[18px] w-full"
                                onClick={() => {
                                    handleChangePlan(plan.tenure.toString());
                                }}
                            >
                                {plan.tenure == selectedPlan?.tenure ? (
                                    <img
                                        src="/assets/images/radio-green.svg"
                                        alt="selected"
                                        className="w-[18px] h-[18px] mr-[8px]"
                                    />
                                ) : (
                                    <input
                                        checked={plan.tenure == selectedPlan?.tenure}
                                        type="radio"
                                        name="plan"
                                        value={plan.tenure}
                                        className="mr-[8px] h-[18px] w-[18px] appearance-none rounded-full border-1 border-[#90A4A7]"
                                    />
                                )}
                                {planList?.filter((plan) => plan.tenure === 1).length ? (
                                    <div className="relative w-full">
                                        <Bold14Text
                                            text={`Pay ₹${plan?.tdp?.toFixed(0)} Now + ${
                                                plan.tenure === 1
                                                    ? '₹' + finalEmi.toFixed(0) + ' Later'
                                                    : plan.tenure + ' EMIs of ₹' + finalEmi.toFixed(0)
                                            } `}
                                            className="text-[#3D494B] leading-[12.19px] !font-robotoBold"
                                        />
                                        <div className={`flex absolute top-[20px]`}>
                                            <Regular12Text
                                                className="italic leading-[12.19px] -mt-[4px] !font-robotoRegular text-[#657173]"
                                                text={`${
                                                    plan.tenure === 1
                                                        ? `Pay ₹${finalEmi.toFixed(0)} later on`
                                                        : 'Pay EMIs on Snapmint Starting'
                                                } `}
                                            />
                                            &nbsp;
                                            <span className="italic relative !font-robotoRegular leading-[12.19px] text-[12px] -mt-[4px] text-[#657173]">
                                                <span className="!font-robotoRegular">
                                                    {new Date(selectedPlan.emiStartDate).getDate()}
                                                </span>
                                                <span className="!font-robotoRegular text-[12px] absolute top-[-4px] left-4">
                                                    rd
                                                </span>
                                                &nbsp;&nbsp;
                                                <span className="ml-[7px] !font-robotoRegular">
                                                    {new Date(selectedPlan.emiStartDate).toLocaleString('default', {
                                                        month: 'short'
                                                    })}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <Bold14Text
                                            text={`₹${finalEmi?.toFixed(0)}`}
                                            className="text-[#3D494B] leading-[12.19px]"
                                        />
                                        &nbsp;
                                        <Regular14Text
                                            text={` x ${plan.tenure} months`}
                                            className="text-[#657173] !font-robotoMedium leading-[12.19px]"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="flex-1 w-max">
                                {plan.isZeroPercent ? (
                                    <SnapBadge className= {`!bg-[#AFE137] rounded-[13px] ${plan.tenure === 1 ? 'w-[65px]' : 'w-[54px]'} h-[16px] w-[54px] px-[8px] flex justify-center items-center`}>
                                        <Bold7Text
                                            text={plan.tenure === 1 ? '0 Extra Cost' : '0% EMI'}
                                            className="!text-[#014751] !text-[9px] !font-robotoBold"
                                        />
                                    </SnapBadge>
                                ) : (
                                    <Regular12Text
                                        text={`(${emiPercentage}% per mon*)`}
                                        className="!leading-normal whitespace-nowrap !rounded-[13px] !text-[10px] !text-[#657173] !font-robotoMedium"
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
                 <Regular12Text
                    className={`italic leading-[12.19px] text-[#515D5F] !text-[11px] ${
                        planList?.filter((plan) => plan.tenure === 1).length ? 'hidden' : ''
                    }`}
                    text={`*Total extra payment per month/order value`}
                />
                {selectedPlan && (
                    <SnapPrimaryButton
                        active={true}
                        text={`${
                            selectedPlan?.tenure === 1
                                ? 'Pay Later'
                                : planList?.filter((plan) => plan.tenure === 1).length
                                ? `Pay in ${selectedPlan.tenure} Monthly Installments`
                                : `Pay in ${selectedPlan.tenure} Monthly EMIs`
                        } `}
                        className="w-full text-[16px] mt-[13px] h-[35px] mb-[8px] px-[16px] py-[8px] leading-[12.19px] font-robotoRegular"
                        onClick={(e: any) => {
                            onSelect(selectedPlan, variableDpOptionList, true);
                        }}
                    />
                )}
            </div>
        </div>
    );
};
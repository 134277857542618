import Axios, { AxiosResponse } from 'axios';
import { BASE_API_PATH } from '../enum/base.enum';

export const AshieldService = {
    getSignature(checkoutId: string): Promise<AxiosResponse<{ signature: string }>> {
        return Axios.get(`${BASE_API_PATH}/ashield/signature?checkoutId=${checkoutId}`);
    },
    authorize(checkoutId: string) {
        return Axios.post(`${BASE_API_PATH}/ashield/authorize?checkoutId=${checkoutId}`);
    }
};

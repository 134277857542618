import Axios, { AxiosResponse } from 'axios';
import { BASE_API_PATH } from '../enum/base.enum';
import { MerchantHeaderDetails } from '../app.reducer';

export const MerchantService = {
    getMerchantHeaderDetails(
        merchantId: string,
        checkoutId?: string
    ): Promise<AxiosResponse<Omit<MerchantHeaderDetails, 'isLoaded'>>> {
        return Axios.get(`${BASE_API_PATH}/merchant/header-details?merchantId=${merchantId}&checkoutId=${checkoutId}`);
    }
};

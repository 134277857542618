import { useApp } from '../../app.hook';
import { BASE_PATH } from '../../enum/base.enum';
import { isHeaderVisible } from '../../utils/web-view.helper';

interface HeaderProps {
    openCloseAlert: () => void;
    isTravelCart?: boolean
}

const Header = ({ openCloseAlert , isTravelCart }: HeaderProps) => {
    const { isIframe, viewType, headerDetails } = useApp();

    const { showMerchantHeader = false, merchantLogo = '', merchantHeaderTitle = '' } = headerDetails;
    return (
        <>
            {isHeaderVisible(viewType) && (
                <>
                    {showMerchantHeader ? (
                        <div
                            className={`${
                                isIframe ? 'sticky' : ''
                            } flex h-[52px] w-full top-0 fixed items-center bg-white z-[999999] justify-between px-8 shadow-[2px_2px_11px_0px_rgba(1,71,81,0.10)]`}
                        >
                            <div className="flex items-center">
                                <div className="w-16 h-16 flex items-center justify-center border border-[#E0E1DD] rounded-[4px]">
                                    <img src={merchantLogo} className="w-[22px] h-8" />
                                </div>
                                <div className="ml-4 text-[14px] font-openSansBold text-[#3D494B]">
                                    {merchantHeaderTitle}
                                </div>
                            </div>
                            {isIframe && (
                                <img
                                    src={`${BASE_PATH}/assets/images/cross-icon-dark.svg`}
                                    className="h-[100%] right-0 cursor-pointer w-12 h-12"
                                    alt=""
                                    onClick={openCloseAlert}
                                />
                            )}
                        </div>
                    ) : (
                        <div
                            className={`${
                                isIframe ? 'sticky' : ''
                            } bg-headerBg ${isTravelCart ? "h-[50px]" : "h-[40px]"}  fixed w-[100%] mx-auto top-0 flex items-center z-[999999]`}
                        >
                            <img src={`${BASE_PATH}/assets/images/snapgreen_logo.svg`} className=" ml-8" alt="" />
                            {isIframe && (
                                <img
                                    src={`${BASE_PATH}/assets/images/cross_icon.svg`}
                                    className="absolute p-6 h-[100%] right-0 cursor-pointer"
                                    alt=""
                                    onClick={openCloseAlert}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Header;

import { useEffect} from 'react';
import { OrderDetailComponent } from '../../component/order-detail/order-detail.component';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { OtpComponent } from '../../component/otp/otp.component';
import { OtpStateProvider } from './otp.context';
import { useOtp } from './otp.hook';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { PreEmiLoaderComponent } from '../../component/loader/pre-emi-loader.component';
import { useRouteValidation } from '../common/route-validation.hook';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';
import { OrderDetailSuperComponent } from '../../component/order-detail/order-detail-super-component';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashEnum } from '../../enum/unleash-enum';
import { PersonalLoan } from '../../enum/enum'
import PersonalLoanLoader from '../../component/loader/personal-loan-loader.component'

const OTPPageBase = () => {

    const {
        loading,
        otp,
        termAccepted,
        otpReSendError,
        otpReSendMessage,
        validateOtpRes,
        initState,
        checkForCompleteValidation,
        updateOTPResendStatus,
        upadteOtp,
        updateLoading,
        updateFields,
        posthogEventHandle
    } = useOtp();

    const _transactionParamModel = useUrlParamParser();
    const { checkRedirection } = useRedirectionHelper();
    const { state }: any = useRouteValidation();
    const tncExperiment = useFlag(UnleashEnum.CHECKOUT_TNC_TOGGLE);
    const planOptionShow = useFlag(UnleashEnum.PLAN_CHANGE_COMPONENT);
    const { emiPlanId, isPlFlow } = _transactionParamModel;

    useEffect(() => {
        if (validateOtpRes && validateOtpRes.data) {
            let eventData: any = {};
            if (_transactionParamModel) {
                const { merchantName, merchantId, checkoutId, userId, mobile } = _transactionParamModel;
                eventData.user_channel = 'checkout';
                eventData.cart_id = checkoutId;
                eventData.merchant_id = merchantId;
                eventData.merchant_name = merchantName;
                eventData.user_id = userId;
                eventData.mobile_no = mobile;
            }
            if (validateOtpRes.data.redirect && validateOtpRes.data.redirect.url) {
                captureEvent(EventName.OTP_VERIFIED, eventData, true);
                captureEvent(EventName.CHECKOUT_USER_CHANNEL, {}, false , true , {checkout_user_channel: "online mobile"});

            }

            const { redirect } = checkRedirection(validateOtpRes.data);
            updateLoading(redirect);
        }
    }, [validateOtpRes]);

    useEffect(() => {
        if (_transactionParamModel) {
            initState(_transactionParamModel);
        }
    }, [_transactionParamModel.checkoutId]);

    useEffect(() => {
        checkForCompleteValidation();
    }, [otp.value, termAccepted]);

    useEffect(() => {
        if (state && state.sendOTPRes) {
            updateFields(state.sendOTPRes)
            if (state.sendOTPRes && state.sendOTPRes.status == 'error' && state.sendOTPRes.message) {
                updateOTPResendStatus(true, state.sendOTPRes.message);
            }
        }
    }, [state]);

    useEffect(() => {
        let eventData: any = {};
        if (_transactionParamModel) {
            const { merchantName, merchantId, checkoutId, mobile, userId } = _transactionParamModel;
            eventData.user_channel = 'online mobile';
            eventData.cart_id = checkoutId;
            eventData.merchant_id = merchantId;
            eventData.merchant_name = merchantName;
            eventData.mobile_no = mobile;
            eventData.user_id = userId;
            eventData.tnc_version = tncExperiment ? 'medium':'high';
            eventData.plan_option_shown = planOptionShow;
        }
        const gtmProperties = {
            ...eventData,
            order_value: _transactionParamModel.orderValue, 
        }
        captureEvent(EventName.OTP_PV, eventData, true , true, gtmProperties);
        captureEvent(EventName.CHECKOUT_USER_CHANNEL, {}, false , true , {checkout_user_channel: "online mobile"});

    }, []);

    useEffect(() => {
        if ('OTPCredential' in window) {
            posthogEventHandle(EventName.OTP_AUTOREAD_REQUESTED);
            const ac = new AbortController();
            const options: any = {
                otp: { transport: ['sms'] },
                signal: ac.signal
            };
            navigator.credentials
                .get(options)
                .then((otp: any) => {
                    posthogEventHandle(EventName.OTP_AUTOREAD_SUBMITTED);
                    upadteOtp(otp.code);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log('otp auto detect failed');
        }
    }, []);

    return (
        <>
            <div className="max-w-[374px] pt-[8px] px-[16px] mx-auto">
                <OrderDetailSuperComponent showCashBack={true} />
                {!loading && <OtpComponent tncExperiment={tncExperiment}/>}
                {loading && (isPlFlow ? <PersonalLoanLoader subTitle='We are fetching your bank account details'  /> :  <PreEmiLoaderComponent />)}
            </div>
        </>
    );
};

const OTPPage = () => {
    return (
        <OtpStateProvider>
            <OTPPageBase />
        </OtpStateProvider>
    );
};

export default OTPPage;

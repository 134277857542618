export enum UnleashEnum {
    NEW_CHECKOUT_CASHBACK = 'New_Checkout_Cashback',
    REMOVE_NACH_EXP = 'RemoveNACH_Exp',
    CHECKOUT_TNC_MITC_TOGGLE = 'CHECKOUT_TNC_MITC_TOGGLE',
    CHECKOUT_TNC_TOGGLE = 'CHECKOUT_TNC_TOGGLE',
    NEW_KYC_SERVICE = 'SNAP-16348_New_Kyc_Service',
    PLAN_CHANGE_COMPONENT = 'Plan_change_component',
    CASHBACK_ROLLOUT = 'CASHBACK_ROLLOUT',
    CHECKOUT_UPI_INTENT_ON_CLICK = 'CHECKOUT_UPI_INTENT_ON_CLICK'
}

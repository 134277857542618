import { useContext, useEffect } from "react"
import { OtpContext } from "./otp.context"

export const useOtp = () => {
    const context = useContext(OtpContext)

    if (context === undefined) {
        throw new Error("useOtp must be used within OtpContext")
    }

    return context
}
export const setLocalStorageKey = (key: string, value: any) => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            localStorage.setItem(key, value);
        }
    } catch (err) {
        console.log(err);
    }
};

export const getLocalStorageKey = (key: string): string | null => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const removeLocalStoregaeKey = (key: string) => {
    try {
        const localStorage = window?.localStorage;
        if (localStorage) {
            localStorage.removeItem(key);
        }
    } catch (error) {
        console.log(error);
    }
};
